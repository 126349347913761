import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { dispatch } from "store/index";
import { callsLogColumns } from "components/columns/columns";
import { FetchAllCallLogs } from "store/action/index";
import { textAlign } from "../../../node_modules/@mui/system/index";
const CallLogs = ({ allcallogs }) => {
    const [loading, setLoading] = useState(false);
    const [params, setparams] = useState({ page: 1 });



    useEffect(() => {
        setLoading(true);
        dispatch(FetchAllCallLogs({ setLoading: setLoading }));
    }, []);


    useEffect(() => {
        console.log("allcompaigns", allcallogs)
    }, [allcallogs]);


    // const columns = callsLogColumns();

    const columns = callsLogColumns({ params, setparams });



    //   const handlePageChange = (page) => {
    //     setparams({ ...params, page });
    //   };


    const allCampaignsData = [
        {
            id: 1,
            Duration: "15 minutes",
            start_date: "2024-10-01",
            LeadName: "John Doe",
            AgentName: "Alice Smith",
            LeadNumber: "+123456789",
            DownloadAudio: "audio1.mp3",
        },
        {
            id: 2,
            Duration: "10 minutes",
            start_date: "2024-10-02",
            LeadName: "Jane Doe",
            AgentName: "Bob Johnson",
            LeadNumber: "+987654321",
            DownloadAudio: "audio2.mp3",
        },
        {
            id: 3,
            Duration: "5 minutes",
            start_date: "2024-10-03",
            LeadName: "Mike Ross",
            AgentName: "Charlie Brown",
            LeadNumber: "+456123789",
            DownloadAudio: "audio3.mp3",
        },
        {
            id: 4,
            Duration: "20 minutes",
            start_date: "2024-10-04",
            LeadName: "Rachel Green",
            AgentName: "Monica Geller",
            LeadNumber: "+321654987",
            DownloadAudio: "audio4.mp3",
        },
    ];

    return (
        <>
            <MainCard>
                <Table
                    dataSource={allCampaignsData}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    rowKey="id"
                />
                {/* <div style={{ textAlign: "center", marginTop: 16 }}>
                    <Pagination
                        current={allcompaigns?.meta?.current_page || 1}
                        total={allcompaigns?.meta?.total || 0}
                        pageSize={allcompaigns?.meta?.per_page || 10}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                    />
                </div> */}
            </MainCard>
        </>
    )
}





const mapStateToProps = (state) => {
    return {
        allcallogs: state?.AllCallLogsReducer?.allcallogs,
    };
};

export default connect(mapStateToProps)(CallLogs);