import * as url from "../constant/Endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { objectToQueryString } from "helper/urlquerystring";
import { AES, enc } from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import {
  FetchGetAllTags,
  FetchAllRoleView as FetchAllRoleViewAgain,
  FetchAllEmailTemplates,
  FetchAllEmailSettings,
  FetchAllTeams,
  FetchAllCallScript,
  FetchAllCompaigns,
  FetchAllActivitiesOfCamp,
  FetchAllWhatsappTemplates,
  StartActivityProcess,
  FetchActivityDetails,
} from "store/action/index";
import { dispatch } from "store/index";
import { isAuthenticated, toastStyle } from "helper/helper";
const CryptoJS = require("crypto-js");

export default {
  fetchLoginUser: (payload, cb) => {
    if (payload.email === "" || payload.password === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
      password: payload.password,
    };

    PostData(url.base_url + url.login_api_route, data)
      .then((res) => {
        const encryptedToken = CryptoJS.AES.encrypt(
          res.data.data.token,
          url.encryptionSecret
        ).toString();

        const encryptedLoginData = CryptoJS.AES.encrypt(
          JSON.stringify(res?.data?.data),
          url.encryptionSecret
        ).toString();
        localStorage.setItem("token", encryptedToken);
        localStorage.setItem("Id", res.data.data.id);
        localStorage.setItem("data", encryptedLoginData);
        window.location.reload();
        window.location.href = "/";
        toast.success(res?.data?.message, toastStyle);
        payload.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        console.log(err?.response?.data);
        if (err?.response?.data?.message === "Unauthorised.") {
          toast.error("Invalid Email or Password", toastStyle);
        }
        isAuthenticated(err?.message);
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  ForgetPassword: (payload, cb) => {
    if (payload.email === "") {
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
    };

    PostData(url.base_url + url.ForgetPassword, data)
      .then((res) => {
        toast.success(res?.data?.message, toastStyle);

        payload.setLoading(false);
        setTimeout(() => {
          localStorage.setItem("email-forget", payload.email);
          window.location = "/otp-password";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, toastStyle);
      });
  },
  VerifyOTP: (payload, cb) => {
    if (
      payload.email === "" ||
      payload.password === "" ||
      payload.c_password === ""
    ) {
      payload.setLoading(false);

      return false;
    }
    if (payload.password !== payload.c_password) {
      toast.error("New Password & Confirm Password does not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
      otp: payload.otp,
      password: payload.password,
      c_password: payload.c_password,
    };

    PostData(url.base_url + url.VerifyOTP, data)
      .then((res) => {
        toast.success(res?.data?.message, toastStyle);

        localStorage.removeItem("email-forget");
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CreateAddUsers: (payload, cb) => {
    PostRegisterData(url.base_url + url.createUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },
  EditUpdateUsers: (payload, cb) => {
    PostRegisterData(url.base_url + url.updateUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  GetAllUsers: (payload, cb) => {
    getData(url.base_url + url.getAllUsers)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  CreateFetchRolee: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.registerRollsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Created Successfully", toastStyle);
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  EditRole: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.editRoleAPI, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Edited Successfully", toastStyle);
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  AssignPermissionsShop: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.assignpermissionsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Permissions Assigned Successfully", toastStyle);
        // <------- permissions update localstorage -------------------->
        // let permissionUser = localStorage.getItem("data");

        // let decryptedData = null;
        // if (permissionUser) {
        //   try {
        //     // Code that may throw an exception
        //     decryptedData = AES.decrypt(
        //       permissionUser,
        //       url.encryptionSecret
        //     ).toString(enc.Utf8);
        //     // ...
        //   } catch (error) {
        //     localStorage.clear();
        //     window.location.reload();
        //   }
        // }
        // let permissionsArray = [];

        // try {
        //   permissionsArray = decryptedData && JSON.parse(decryptedData);
        // } catch (e) {}

        // <------- permissions update localstorage -------------------->

        window.location.href = "/";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  FetchRegisterFetchCategory: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.getAllCounts, data)
      .then((res) => {
        toast.success("Category Added Successfully", toastStyle);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  createLeadActivities: (payload, cb) => {
    const data = payload?.fieldData;

    JsonPostData(
      url.base_url + url.addLead_activityapi + data?.activity_id,
      data
    )
      .then((res) => {
        toast.success("Add Leads Successfully", toastStyle);
        payload?.handleNavigate?.()
        dispatch(FetchActivityDetails({ id: res.data.id }))
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  getAllActivity: (payload, cb) => {
    getData(url.base_url + url.callActivity)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
  },
  getAllPermissions: (payload, cb) => {
    getData(url.base_url + url.allpermissionsApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
  },
  GetRolePermissions: (payload, cb) => {
    getData(url.base_url + url.getRolePermissionsApi + `/${payload?.roleId}`)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  HandleEditUserApi: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.edituserapis, data)
      .then((res) => {
        cb(res.data);
        toast.success("User Edited Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  FetchAllUsersView: (payload, cb) => {
    getData(url.base_url + url.allUsersApi + "?page=" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
  },

  FetchUsersStatusUpdateView: (payload, cb) => {
    getData(url.base_url + url.usersStatusUpdateApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
  },

  FetchStatusChange: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllCounts + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
    payload?.setLoading(false);
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  FetchAllRoleView: (payload, cb) => {
    getData(url.base_url + url.allRoleApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
      });
  },

  HitCompaignsApi: (payload, cb) => {
    getData(
      url.base_url +
      url.compaigns_route_api +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.call_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  SmSLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.sms_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  EmailLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.email_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  WhatsAppLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.whatsapp_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateCampaign: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(url.base_url + url.compaigns_route_api, data)
      .then((res) => {
        cb(res.data);
        toast.success("Create Campaign Successfully", toastStyle);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
        dispatch(FetchAllCompaigns());
        if (payload?.onClose) {
          payload?.onClose();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  GetAllLeads: (payload, cb) => {
    getData(
      url.base_url + url.get_all_leads + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        cb(err?.response?.status);
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);

        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineDetailLeadsAllData: (payload, cb) => {
    getData(url.base_url + url.leads_details + payload?.id)
      .then((res) => {
        payload?.setLoadingD(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoadingD(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateLeads: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(url.base_url + url.get_all_leads, data)
      .then((res) => {
        cb(res.data);
        toast.success("Create Tags Successfully", toastStyle);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineTags: (payload, cb) => {
    getData(
      url.base_url + url.get_all_tags + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCrTags: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(url.base_url + url.get_all_tags, data)
      .then((res) => {
        cb(res.data);
        dispatch(FetchGetAllTags());
        toast.success("Create Tags Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEditTags: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(
      url.base_url + url.get_all_tags + `/${payload?.fieldData?.id}`,
      data
    )
      .then((res) => {
        cb(res.data);
        dispatch(FetchGetAllTags());
        toast.success("Create Tags Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  // _______ ACTIVITIES DATA _________________
  CallEngineActivities: (payload, cb) => {
    getData(
      url.base_url + url.all_activities + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  // _______ ACTIVITIES DATA _________________

  // __________ EMAIL TEMPLATES ________________
  CallEngineEmailTemplates: (payload, cb) => {
    getData(
      url.base_url + url.email_templates + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineAddEmailTemplates: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.email_templates, data)
      .then((res) => {
        cb(res.data);
        if (payload.handleTemplateChange) {
          payload.handleTemplateChange(res.data?.data?.id);
        }
        dispatch(FetchAllEmailTemplates());
        toast.success("Create Email Template Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineDeleteEmailTemplate: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.email_templates + "/" + data?.id, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        dispatch(FetchAllEmailTemplates());
        toast.success("Delete Email Template Successfully", toastStyle);
      })
      .catch((err) => {
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddWhatsappTemplates: (payload, cb) => {
    JsonPostData(url.base_url + url.whatsapp_templates, payload?.matcherFormat)
      .then((res) => {
        cb(res.data);
        toast.success("Create Whatsapp Template Successfully", toastStyle);

        if (payload?.handleNavigate) {
          payload.handleNavigate?.()
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddEmailSetting: (payload, cb) => {
    JsonPostData(url.base_url + url.email_setting, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllEmailSettings());
        toast.success("Add Email Setting Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineCreateNewWhatsappSetting: (payload, cb) => {
    JsonPostData(url.base_url + url.whtsapp_setting, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllEmailSettings());
        toast.success("Add Whatsapp Setting Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  // --------------------  activity  ----------------------
  CallEngineCreateAddActivity: (payload, cb) => {
    JsonPostData(url.base_url + url.add_activity, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Added Activity Successfully", toastStyle);
        if (payload.onClose) {
          payload.onClose();
        }
        dispatch(
          FetchAllActivitiesOfCamp({ id: payload?.fieldData.campaign_id })
        );
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAllActivitiesOfCamp: (payload, cb) => {
    JsonPostData(url.base_url + url.campaign_activity + `/${payload.id}`)
      .then((res) => {
        cb(res.data);
        // toast.success("Added Activity Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineActivityDetails: (payload, cb) => {
    getData(url.base_url + url.activity_detail + `/${payload.id}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        toast.error(err?.response?.data?.message, toastStyle);
      });
  },

  // ----------------- activity end ------------------------
  CallEngineFetchCreateCallscripts: (payload, cb) => {
    JsonPostData(url.base_url + url.call_script_api, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllCallScript());
        toast.success("Add Call Script Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },


  CallEngineFetchEditCallscripts: (data, cb) => {
    UpdateData(url.base_url + url.call_script_api, data)
      .then((res) => {
        toast.success("Edit Call Script Successfully", toastStyle);
        // data?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        // data?.setLoading(false);
        cb(err?.response?.status);
      });
  },



  CallEngineLeadPostHistory: (payload, cb) => {
    getData(
      url.base_url +
      url.lead_historys +
      payload?.lead_id +
      objectToQueryString(payload?.fieldData)
    )
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllCallScript());
        // toast.success("Add Call Script Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineFetchCHannelPost: (payload, cb) => {
    getData(
      url.base_url +
      url.fecthChannels 
    )
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllWhatsappTemplates());
        toast.success("Sync Successfully", toastStyle);
        payload?.setLoading(false)
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        payload?.setLoading(false)
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEditActivities: (payload, cb) => {
    JsonPostData(
      url.base_url + url.edit_activities + payload?.fieldData?.activity_id,
      payload?.fieldData
    )
      .then((res) => {
        dispatch(StartActivityProcess({ activity_id: res?.data?.id }))
        if (payload.isNavigate) {
          payload.isNavigate()
        }
        cb(res.data);
        toast.success("Edit Activity Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineActivityProcess: (payload, cb) => {
    JsonPostData(
      url.base_url + url.process_activities + payload?.activity_id,
    )
      .then((res) => {
        cb(res.data);
        toast.success("Edit Activity Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddWhatsAppSetting: (payload, cb) => {
    getData(
      url.base_url + url.whtsapp_setting + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineSmsSettings: (payload, cb) => {
    getData(
      url.base_url + url.whtsapp_setting + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  SmsTemplates: (payload, cb) => {
    getData(
      url.base_url + url.sms_templates_api
      // + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  SmsCrcTemplates: (payload, cb) => {
    PostRegisterData(url.base_url + url.sms_templates_api, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        cb(res.data);
        // window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  SmsEditTemplates: (payload, cb) => {
    UpdateData(url.base_url + url.sms_templates_api, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        cb(res.data);
        // window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  // Teams

  CallEngineAllTeams: (payload, cb) => {
    getData(url.base_url + url.teams + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineFetchCallscripts: (payload, cb) => {
    getData(
      url.base_url + url.call_script_api + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineGetWhatsappChannel: (payload, cb) => {
    getData(
      url.base_url +
      url.channel_get_api_route +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateTeams: (payload, cb) => {
    JsonPostData(url.base_url + url.teams, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllTeams());
        toast.success("Added Team Successfully", toastStyle);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineUpdateTeams: (payload, cb) => {
    JsonPostData(
      url.base_url + url.teams + `/${payload?.fieldData?.id}`,
      payload?.fieldData
    )
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllTeams());
        toast.success("Updated Team Successfully", toastStyle);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  //  Teams

  CallEngineUpdateEmailSetting: (payload, cb) => {
    JsonPostData(url.base_url + url.email_setting, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllEmailSettings());
        toast.success("Update Email Setting Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEditEmailTemplates: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.email_templates + `/${data.id}`, data)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllEmailTemplates());
        toast.success("Updated Email Template Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  // __________ WHSTAPP TEMPLATES ________________

  CallEngineWhatsappTemplates: (payload, cb) => {
    getData(
      url.base_url +
      url.whatsapp_templates +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEmailSettings: (payload, cb) => {
    getData(
      url.base_url + url.email_setting + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages) ? errorMessages[0] : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
};

function PostData(URL, data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(URL, data, config);
}
export function JsonPostData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.post(URL, data, config);
}
function PostRegisterData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.post(URL, data, config);
}
function UpdateData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.put(`${URL}/${data.id}`, data, config);
}

function getData(URL) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.get(URL, config);
}
