import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { dispatch } from "store/index";
import { emailLogColumns } from "components/columns/columns";
import { FetchAllEmailLogs } from "store/action/index";
import { textAlign } from "../../../node_modules/@mui/system/index";

const EmailLogs = ({ allemailslogs }) => {
  const [loading, setLoading] = useState(false);
  const [params, setparams] = useState({ page: 1 });

  useEffect(() => {
    setLoading(true);
    dispatch(FetchAllEmailLogs({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    console.log("allcompaigns", allemailslogs);
  }, [allemailslogs]);

  const columns = emailLogColumns({ params, setparams });

  //   const handlePageChange = (page) => {
  //     setparams({ ...params, page });
  //   };
  const allCampaignsData = [
    {
      key: '1',
      LeadName: 'Alice Brown',
      Email: 'alice.brown@example.com',
      SentTime: '2024-10-14 10:00:00',
      status: 'Sent'
    },
    {
      key: '2',
      LeadName: 'Bob Green',
      Email: 'bob.green@example.com',
      SentTime: '2024-10-14 09:45:00',
      status: 'Failed'
    },
    {
      key: '3',
      LeadName: 'Charlie White',
      Email: 'charlie.white@example.com',
      SentTime: '2024-10-14 11:30:00',
      status: 'Read'
    },
    {
      key: '4',
      LeadName: 'Diana Black',
      Email: 'diana.black@example.com',
      SentTime: '2024-10-14 08:50:00',
      status: 'Sent'
    }
  ];


  return (
    <>
      <MainCard>
        <Table
          dataSource={allCampaignsData}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey="id"
        />
        {/* <div style={{ textAlign: "center", marginTop: 16 }}>
              <Pagination
                  current={allcompaigns?.meta?.current_page || 1}
                  total={allcompaigns?.meta?.total || 0}
                  pageSize={allcompaigns?.meta?.per_page || 10}
                  onChange={handlePageChange}
                  showSizeChanger={false}
              />
          </div> */}
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allemailslogs: state?.AllEmailLogsReducer?.allemailslogs,
  };
};

export default connect(mapStateToProps)(EmailLogs);
