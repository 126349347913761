import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Form,
  Input,
  Select,
  Typography,
  Card,
  Timeline,
  Space,
  Modal,
  Checkbox
} from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  PhoneOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const CRMLeadDetails = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(0);
  const [isCallActive, setIsCallActive] = useState(false);
  const [hasCallEnded, setHasCallEnded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [followUpOptions, setFollowUpOptions] = useState({
    scheduleCall: false,
    createQuestion: false,
  });

  useEffect(() => {
    let interval = null;
    if (isCallActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCallActive]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleStartCall = () => {
    setIsCallActive(true);
    setHasCallEnded(false);
  };

  const handleHangUp = () => {
    setIsCallActive(false);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setHasCallEnded(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setHasCallEnded(true);
  };

  const handleFollowUpChange = (option) => {
    setFollowUpOptions(prev => ({
      ...prev,
      [option]: !prev[option]
    }));
  };

  const handleNextCall = () => {
    setSeconds(0);
    setHasCallEnded(false);
    setFollowUpOptions({
      scheduleCall: false,
      createQuestion: false,
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={250} theme="light">
        <div style={{ padding: "20px" }}>
          <Title level={4}>{formatTime(seconds)}</Title>
          <Space direction="vertical" style={{ width: '100%', marginTop: '20px' }}>
            <Button
              type="primary"
              onClick={handleStartCall}
              disabled={isCallActive}
              block
            >
              Start Call
            </Button>
            <Button
              type="primary"
              danger
              onClick={handleHangUp}
              disabled={!isCallActive}
              block
            >
              Hang Up
            </Button>
            <Button
              type="primary"
              onClick={handleNextCall}
              disabled={!hasCallEnded}
              block
            >
              Next Call
            </Button>
          </Space>
        </div>
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">Lead Details</Menu.Item>
        </Menu>
      </Sider>

      <Modal
        title="Follow-up Actions"
        visible={isModalVisible}
        onOk={handleModalOk}
        style={{ marginTop: "40px" }}
        onCancel={handleModalCancel}
        okText="Confirm"
        cancelText="Skip"
      >
        <Space direction="vertical" style={{ width: '100%', marginTop: "16px" }}>
          <Checkbox
            checked={followUpOptions.scheduleCall}
            onChange={() => handleFollowUpChange('scheduleCall')}
          >
            Schedule Follow-up Call
          </Checkbox>
          <Checkbox
            checked={followUpOptions.createQuestion}
            onChange={() => handleFollowUpChange('createQuestion')}
          >
            Create More Questions
          </Checkbox>
        </Space>
      </Modal>

      <Layout>
        <Header style={{ background: "#fff", padding: 0 }}>
          <Menu mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1">Lead Details</Menu.Item>
            <Menu.Item key="2">Call Logs</Menu.Item>
            <Menu.Item key="3">Notes</Menu.Item>
          </Menu>
        </Header>
        <Content
          style={{ margin: "24px 16px", padding: 24, background: "#fff", position: "relative" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8">
                <Form layout="vertical">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="Reference Number" required>
                        <Input placeholder="Please Enter Reference Number" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Lead Status">
                        <Select placeholder="Select Lead Status">
                          <Select.Option value="new">New</Select.Option>
                          <Select.Option value="contacted">Contacted</Select.Option>
                          <Select.Option value="qualified">Qualified</Select.Option>
                          <Select.Option value="lost">Lost</Select.Option>
                          <Select.Option value="won">Won</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="First Name">
                        <Input placeholder="Fausto" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Last Name">
                        <Input placeholder="Reichert" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="Company">
                        <Input placeholder="Frami Group" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Email">
                        <Input placeholder="waters.adelbert@example.com" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Item label="Website">
                        <Input placeholder="mccullough.info" />
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item label="Phone No.">
                        <Input placeholder="+14804370560" />
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item label="Notes">
                    <Input.TextArea
                      rows={4}
                      placeholder="I was thinking I should be raving mad--at least not so mad as it."
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="col-md-4">
                <Card title="Lead History">
                  <Timeline>
                    <Timeline.Item
                      dot={<FileTextOutlined style={{ fontSize: "16px" }} />}
                    >
                      A new notes added by Admin on 25-09-2024 10:09 am
                    </Timeline.Item>
                    <Timeline.Item
                      dot={<UserOutlined style={{ fontSize: "16px" }} />}
                    >
                      A booking added by Admin for salesman Dave Wilkinson for
                      24-09-2024 09:30 pm
                    </Timeline.Item>
                    <Timeline.Item
                      dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                    >
                      Follow up added by Admin for staff member Admin for
                      24-09-2024 07:30 pm
                    </Timeline.Item>
                    <Timeline.Item
                      dot={<PhoneOutlined style={{ fontSize: "16px" }} />}
                    >
                      A new call started by Admin on 25-09-2024 09:34 am
                    </Timeline.Item>
                  </Timeline>
                </Card>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CRMLeadDetails;