import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Pagination, Button } from "antd";
import { Grid } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { allTagsColumns } from "components/columns/columns";
import { dispatch } from "store/index";
import {
  FetchGetAllTags,
  FetchCreateAllTags,
  FetchEditTags,
} from "store/action/index";
import TagModal from "./AddeditTagmodal";

const AllTags = ({ allTagsData }) => {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({ page: 1 });
  const [editData, setEitData] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(FetchGetAllTags({ setLoading: setLoading, params: params }));
  }, [params]);

  const columns = allTagsColumns({ params, setParams });

  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const HandleAddTags = () => {
    setEitData({});
    setIsEditing(false);
  };

  columns.push({
    title: "Action",
    key: "actions",
    render: (text, record) => {
      return (
        <TagModal initialValues={record} setLoading={setLoading}>
          <Button
            type="link"
            icon={<EditOutlined className="large-icon" />}
          ></Button>
        </TagModal>
      );
    },
  });

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Tags
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <TagModal setLoading={setLoading}>
                  <Button
                    disableElevation
                    className="px-2"
                    size="large"
                    onClick={HandleAddTags}
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoMdAddCircle color="#0055A2" size={26} />
                    </span>
                    Add Tag
                  </Button>
                </TagModal>
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      <MainCard>
        <Table
          dataSource={allTagsData?.data ?? []}
          columns={columns}
          pagination={false}
          loading={loading}
        />

        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={allTagsData?.meta?.current_page || 1}
            total={allTagsData?.meta?.total || 0}
            pageSize={allTagsData?.meta?.per_page || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </MainCard>

      {/* Modal for Adding or Editing Tags */}
      {/* <TagModal
        visible={modalVisible}
        closeModal={closeModal}
        onFinish={onFinish}
        initialValues={editData}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allTagsData: state?.AllGetTagsReducer?.alltags,
  };
};

export default connect(mapStateToProps)(AllTags);
