import React from 'react';
import { Form, Input, Select, Space, Button, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

const EditableFormFields = ({ additionalFields, setAdditionalFields }) => {
    const addField = () => {
        const newId = Math.max(...additionalFields.map(field => field.id), 0) + 1;
        setAdditionalFields([
            ...additionalFields,
            { id: newId, name: "", type: "Single Select", values: [] }
        ]);
    };

    const handleFieldChange = (id, key, value) => {
        setAdditionalFields(fields =>
            fields.map(field =>
                field.id === id ? { ...field, [key]: value } : field
            )
        );
    };

    const handleValuesChange = (id, value) => {
        setAdditionalFields(fields =>
            fields.map(field =>
                field.id === id ? { ...field, values: value.split(',').map(v => v.trim()) } : field
            )
        );
    };

    const deleteField = (id) => {
        setAdditionalFields(fields => fields.filter(field => field.id !== id));
    };

    return (
        <div className="p-4">
            <Form layout="vertical">
                <Title level={4}>Additional Fields & Questions</Title>
                {additionalFields.map((field) => (
                    <div key={field.id} className="bor0">
                        <Space align="baseline" className="w-full">
                            <Form.Item
                                label="Question"
                                rules={[{ required: true, message: "Please input the question" }]}
                                className="w-full"
                            >
                                <Input
                                    placeholder={`Question ${field.id}`}
                                    value={field.name}
                                    onChange={(e) => handleFieldChange(field.id, "name", e.target.value)}
                                    className="w-full"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Type"
                                rules={[{ required: true, message: "Please select a type" }]}
                            >
                                <Select
                                    style={{ width: 120 }}
                                    value={field.type}
                                    onChange={(value) => handleFieldChange(field.id, "type", value)}
                                >
                                    <Option value="Single Select">Single Select</Option>
                                    <Option value="Multiple Select">Multiple Select</Option>
                                    <Option value="Text">Text</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Values"
                                rules={[{ required: field.type !== "Text", message: "Please input values" }]}
                                className="w-full"
                            >
                                <Input
                                    placeholder={field.type === "Text" ? "Enter text" : "Value 1, Value 2, Value 3"}
                                    value={field.values.join(', ')}
                                    onChange={(e) => handleValuesChange(field.id, e.target.value)}
                                    className="w-full"
                                />
                            </Form.Item>

                            <Form.Item label=" ">
                                <Button
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() => deleteField(field.id)}
                                >
                                    Delete
                                </Button>
                            </Form.Item>
                        </Space>
                    </div>
                ))}

                <Button
                    type="dashed"
                    onClick={addField}
                    block
                    icon={<PlusOutlined />}
                    className="mt-4"
                >
                    Add Question
                </Button>
            </Form>
        </div>
    );
};

export default EditableFormFields;