import React from 'react';
import { Form, Input, Select } from 'antd';

const { Option } = Select;

const CustomInput = ({ label, name, rules, placeholder, prefix, type, options, multiple }) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}
        >
            {type === 'select' ? (
                <Select mode={multiple} placeholder={placeholder} allowClear >
                    {options?.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            ) : (
                <Input placeholder={placeholder} prefix={prefix} className='p-2' type={type} />
            )}
        </Form.Item>
    );
};

export default CustomInput;
