import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import {
    FetchAllSMSTemplates,
    FetchSmSSettings,
    FetchEditActivity,
    FetchActivityDetails,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography, Form, Input } from "antd";
import { connect } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeadsLogs } from "components/columns/columns";
import axios from 'axios';
import * as url from "../../store/constant/Endpoints"
const CryptoJS = require("crypto-js");

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

const SMSActivityEdit = ({
    allsmsTemplates,
    allSmssetting,
    activityDetail,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [templateOptions, setTemplateOptions] = useState([]);
    const [isProgrammaticUpdate, setIsProgrammaticUpdate] = useState(false);
    const [hasFormBeenModified, setHasFormBeenModified] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(
        location?.state?.data?.template_id ?? null
    );
    const [selectedProvider, setSelectedProvider] = useState(
        location?.state?.data?.setting_id ?? null
    );
    const [EditActivityTemplate, setEditActivityTemplate] = useState({});
    const [leadsDataSource, setLeadsDataSource] = useState([]);

    useEffect(() => {
        dispatch(FetchAllSMSTemplates());
        dispatch(FetchSmSSettings());
        dispatch(FetchActivityDetails({ id: location.state?.data?.activity_id_specific }));
    }, []);

    useEffect(() => {
        setTemplateOptions(allsmsTemplates);
        console.log("SMSMSMSM",allsmsTemplates)
    }, [allsmsTemplates]);

    const createNewTemplate = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                name: values.name,
                body: values.body,
            };

            return new Promise(async (resolve, reject) => {
                try {
                    let getNewTokenn = localStorage.getItem("token");
                    let NewToekn = "";
                    if (getNewTokenn) {
                      NewToekn = CryptoJS.AES.decrypt(
                        getNewTokenn,
                        url.encryptionSecret
                      ).toString(CryptoJS.enc.Utf8);
                    }
                                      const config = {
                                          headers: {
                                            "Content-Type": "multipart/form-data",
                                            Authorization: `Bearer ${NewToekn}`,
                                          },
                                        };
                  
                    const response = await axios.post(
                        `${url?.base_url}${url.sms_templates_api}`,
                        payload,config
                    );

                    const responseData = response.data;
                    if (responseData) {
                        resolve(responseData.data?.id);

                    }
                } catch (error) {
                    console.error('Error creating new template:', error);
                    reject(error);
                }
            });
        } catch (error) {
            console.error('Form validation failed:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (activityDetail) {
            setLeadsDataSource([...location?.state?.data?.leads, ...(activityDetail?.leads ? activityDetail?.leads : [])]);
        }
    }, [activityDetail]);

    const handleTemplateChange = (value) => {
        setSelectedTemplate(value);
        const selectedTemp = templateOptions?.find(temp => temp.id === value);
        if (selectedTemp) {
            setIsProgrammaticUpdate(true);

            form.setFieldsValue({
                body: selectedTemp.body,
                name: selectedTemp.name,
            });

            setTimeout(() => setIsProgrammaticUpdate(false), 0);
        }
    };

    useEffect(() => {
        if (selectedTemplate && templateOptions?.length !== 0) {
            let filterTemplate = templateOptions?.filter(
                (e) => e?.id === selectedTemplate
            );
            setEditActivityTemplate(filterTemplate[0]);
        }
    }, [selectedTemplate, templateOptions]);

  

    const HandleSave = async (status_id) => {
        try {
            if (status_id === 1 && !selectedProvider) {
                toast.error("Provider must be selected");
                return;
            }

            let finalTemplateId = selectedTemplate;

            if (selectedTemplate && hasFormBeenModified) {
                const newTemplateId = await createNewTemplate();
                finalTemplateId = newTemplateId;
                await Promise.all([
                    dispatch(FetchAllSMSTemplates()),
                    dispatch(FetchSmSSettings())
                ]);
                handleNavigate()
            }

            if (!selectedTemplate && hasFormBeenModified) {
                const newTemplateId = await createNewTemplate();
                finalTemplateId = newTemplateId;

                await Promise.all([
                    dispatch(FetchAllSMSTemplates()),
                    dispatch(FetchSmSSettings())
                ]);
                handleNavigate()
            }

            if (selectedTemplate && !hasFormBeenModified || !selectedTemplate && !hasFormBeenModified) {
                const payload = {
                    activity_id: location?.state?.data?.activity_id_specific,
                    campaign_id: location?.state?.data?.campaign_id,
                    template_id: finalTemplateId,
                    setting_id: selectedProvider,
                    status: status_id,
                    body: form.getFieldValue('body'),
                    name: form.getFieldValue('name'),
                };
                dispatch(FetchEditActivity({ fieldData: payload, isNavigate: handleNavigate }));
            }
        } catch (error) {
            toast.error("Please fill out all required fields");
        }
    };

    const handleNavigate = () => {
        navigate(-1);
    };

    const STATUS = location.state?.data?.status;

    return (
        <MainCard>
            <div>
                <h3 style={{ marginBottom: "20px" }}>Edit SMS Activity</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "left" }}>
                        <AddLeadsDrawer
                            leadsDataSource={leadsDataSource}
                            datacampaign={location?.state?.data}
                            handleNavigate={handleNavigate}
                            status={STATUS}
                        >
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                }}
                                size="large"
                            >
                                <IoMdAddCircle color="#fff" size={24} />
                                Add Leads
                            </Button>
                        </AddLeadsDrawer>
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div className="count-box">
                            <span>Total: </span>{location?.state?.data?.leads?.length}
                        </div>
                        <div className="count-box">
                            <span>New: </span>
                            {Array.isArray(activityDetail?.leads) ? activityDetail?.leads?.length : 0}
                        </div>
                    </div>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        body: EditActivityTemplate?.body || '',
                        name: EditActivityTemplate?.name || '',
                    }}
                    onValuesChange={(changedValues, allValues) => {
                        if (!isProgrammaticUpdate && (changedValues.name || changedValues.body)) {
                            setHasFormBeenModified(true);
                        }
                    }}

                >
                    <div style={{ marginTop: 30 }}>
                        <div style={{ display:"flex", gap:20}}>

                        <div style={{ marginBottom: 10 }}>
                            <label style={{ marginBottom: 5, fontSize: 13 }}>Select Template</label>
                            <Select
                                placeholder="Select Template"
                                style={{ width: 300 }}
                                value={selectedTemplate}
                                disabled={STATUS == 1}
                                onChange={handleTemplateChange}
                            >
                                {templateOptions?.map((template) => (
                                    <Option key={template.id} value={template.id}>
                                        {template.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div style={{ marginBottom: 10 }}>
                            <label style={{ marginBottom: 5, fontSize: 13 }}>Select Provider</label>
                            <Select
                                placeholder="Provider"
                                style={{ width: 300 }}
                                value={selectedProvider}
                                disabled={STATUS == 1}
                                onChange={(v) => setSelectedProvider(v)}
                            >
                                {allSmssetting?.map((setting) => (
                                    <Option key={setting.id} value={setting.id}>
                                        {setting.sender_name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        </div>

                        <Form.Item
                            label="Name"
                            name="name"
                        >
                            <Input placeholder="Enter Name" disabled={STATUS == 1} />
                        </Form.Item>
                        <Form.Item
                            name="body"
                            label="Body"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter SMS message',
                                },
                                {
                                    max: 160,
                                    message: 'SMS message cannot exceed 160 characters',
                                }
                            ]}
                        >
                            <TextArea
                                rows={4}
                                disabled={STATUS == 1}
                                maxLength={160}
                                showCount
                                placeholder="Enter your SMS message here"
                            />
                        </Form.Item>
                    </div>
                </Form>

                {STATUS != 1 && (
                    <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>
                        <Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "13px",
                                minWidth: "150px",
                            }}
                            onClick={() => HandleSave(1)}
                            size="large"
                        >
                            Save
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "13px",
                                minWidth: "150px",
                            }}
                            onClick={() => HandleSave(2)}
                            size="large"
                        >
                            Save as Draft
                        </Button>
                    </div>
                )}

                <Table
                    className="border rounded"
                    style={{ marginTop: 25 }}
                    dataSource={[]}
                    columns={EditActivityLeadsLogs()}
                />
            </div>
        </MainCard>
    );
};

const mapStateToProps = (state) => ({
    allsmsTemplates: state?.AllSmsTemplatesReducer?.allsmsTemplates,
    allSmssetting: state?.AllSmsDataReducer?.allSmssetting?.data,
    activityDetail: state?.ActivityDetailRedc?.activityDetails
});

export default connect(mapStateToProps)(SMSActivityEdit);