import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import {
    FetchAllEmailTemplates,
    FetchAllEmailSettings,
    FetchEditActivity,
    FetchActivityDetails,
    AddEmailTemplate,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography, Form, Input, Checkbox } from "antd";
import { Grid, FormControlLabel } from "@mui/material";
import { connect } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeadsLogs } from "components/columns/columns";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";
import axios from 'axios';
import * as url from "../../store/constant/Endpoints";
const CryptoJS = require("crypto-js");


const { Option } = Select;
const { Title } = Typography;

const EmailActivityEdit = ({
    emailTemplates,
    emailSettings,
    activityDetail,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(
        location?.state?.data?.template_id ?? null
    );
    const [isProgrammaticUpdate, setIsProgrammaticUpdate] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(
        location?.state?.data?.setting_id ?? null
    );
    const [leadsDataSource, setLeadsDataSource] = useState([]);
    const [editorValue, setEditorValue] = useState("");
    const [isPasteHtml, setIsPasteHtml] = useState(false);
    const [hasFormBeenModified, setHasFormBeenModified] = useState(false);

    useEffect(() => {
        dispatch(FetchAllEmailTemplates());
        dispatch(FetchAllEmailSettings());
        dispatch(FetchActivityDetails({ id: location.state?.data?.activity_id_specific }));
    }, []);

    useEffect(() => {
        setTemplateOptions(emailTemplates);
    }, [emailTemplates]);

    useEffect(() => {
        if (activityDetail) {
            setLeadsDataSource([...location?.state?.data?.leads, ...(activityDetail?.leads ? activityDetail?.leads : [])]);
        }
    }, [activityDetail]);

    const HandleSave = async (status_id) => {
        try {
            if (status_id === 1 && !selectedProvider) {
                toast.error("Provider must be selected");
                return;
            }

            let finalTemplateId = selectedTemplate;

            if (selectedTemplate && hasFormBeenModified) {
                const newTemplateId = await createNewTemplate();
                finalTemplateId = newTemplateId;
                await Promise.all([
                    dispatch(FetchAllEmailTemplates()),
                    dispatch(FetchAllEmailSettings())
                ]);
                handleNavigate()
            }

            if (!selectedTemplate && hasFormBeenModified) {
                const newTemplateId = await createNewTemplate();
                finalTemplateId = newTemplateId;

                await Promise.all([
                    dispatch(FetchAllEmailTemplates()),
                    dispatch(FetchAllEmailSettings())
                ]);
                handleNavigate()
            }

            if (selectedTemplate && !hasFormBeenModified || !selectedTemplate && !hasFormBeenModified) {
                const payload = {
                    activity_id: location?.state?.data?.activity_id_specific,
                    campaign_id: location?.state?.data?.campaign_id,
                    template_id: finalTemplateId,
                    setting_id: selectedProvider,
                    status: status_id,
                };
                dispatch(FetchEditActivity({ fieldData: payload, isNavigate: handleNavigate }));
            }
        } catch (error) {
            toast.error("Please fill out all required fields");
        }
    };

    const handleTemplateChange = (value) => {
        setSelectedTemplate(value);
        if (value) {
            const selectedTemplateData = templateOptions?.find(t => t.id === value);
            if (selectedTemplateData) {
                const formValues = {
                    templateName: selectedTemplateData.name,
                    subject: selectedTemplateData.subject,
                    footer: selectedTemplateData.footer,
                    body: selectedTemplateData.body || "",
                };

                setIsProgrammaticUpdate(true);
                form.setFieldsValue(formValues);
                setEditorValue(selectedTemplateData.body || "");

                setTimeout(() => setIsProgrammaticUpdate(false), 0);
            }
        } else {
            form.resetFields();
            setEditorValue("");
        }
    };


    const createNewTemplate = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                name: values.templateName,
                subject: values.subject,
                body: isPasteHtml ? values.body : editorValue,
                footer: values.footer,
            };

            return new Promise(async (resolve, reject) => {
                try {
                    let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
                    const config = {
                        headers: {
                          "Content-Type": "multipart/form-data",
                          Authorization: `Bearer ${NewToekn}`,
                        },
                      };
                    const response = await axios.post(
                        `${url?.base_url}${url.email_templates}`,
                        payload,config
                    );

                    const responseData = response.data;
                    if (responseData) {
                        resolve(responseData.data?.id);

                    }
                } catch (error) {
                    console.error('Error creating new template:', error);
                    reject(error);
                }
            });
        } catch (error) {
            console.error('Form validation failed:', error);
            throw error;
        }
    };


    const handleNavigate = () => {
        navigate(-1);
    };

    const STATUS = location.state?.data?.status;

    return (
        <MainCard>
            <div>
                <h3 style={{ marginBottom: "20px" }}>Edit Email Activity</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "left" }}>
                        <AddLeadsDrawer
                            leadsDataSource={leadsDataSource}
                            datacampaign={location?.state?.data}
                            handleNavigate={handleNavigate}
                            status={STATUS}
                        >
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                }}
                                size="large"
                            >
                                <IoMdAddCircle color="#fff" size={24} />
                                Add Leads
                            </Button>
                        </AddLeadsDrawer>
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div className="count-box">
                            <span>Total: </span>{location?.state?.data?.leads?.length}
                        </div>
                        <div className="count-box">
                            <span>New: </span>
                            {Array.isArray(activityDetail?.leads) ? activityDetail?.leads?.length : 0}
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 30,display:"flex", gap:20 }}>

                    <div style={{ marginBottom: 10 }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Template</label>
                        <Select
                            placeholder="Select Template"
                            style={{ width: 300 }}
                            value={selectedTemplate}
                            disabled={STATUS == 1}
                            onChange={handleTemplateChange}
                            allowClear
                        >
                            {templateOptions?.map((template) => (
                                <Option key={template.id} value={template.id}>
                                    {template.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{ marginBottom: 10 }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Provider</label>
                        <Select
                            placeholder="Provider"
                            style={{ width: 300 }}
                            value={selectedProvider}
                            disabled={STATUS == 1}
                            onChange={(v) => setSelectedProvider(v)}
                        >
                            {emailSettings?.map((setting) => (
                                <Option key={setting.id} value={setting.id}>
                                    {setting.hostname}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <MainCard style={{ marginTop: 20 }}>
                    <Form form={form}
                        layout="vertical"
                        onValuesChange={(changedValues, allValues) => {
                            if (!isProgrammaticUpdate && (changedValues.templateName || changedValues.subject || changedValues.body || changedValues.footer)) {
                                setHasFormBeenModified(true);
                            }
                        }}

                    >
                        <Form.Item
                            label="Template Name"
                            name="templateName"
                            rules={[{ required: true, message: "Template Name is required" }]}
                        >
                            <Input placeholder="Enter template name" disabled={STATUS == 1} />
                        </Form.Item>

                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[{ required: true, message: "Subject is required" }]}
                        >
                            <Input placeholder="Enter subject" disabled={STATUS == 1} />
                        </Form.Item>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isPasteHtml}
                                    onChange={(e) => setIsPasteHtml(e.target.checked)}
                                    disabled={STATUS == 1}
                                    color="primary"
                                />
                            }
                            label="Edit as a HTML"
                        />

                        <Form.Item
                            label="Body"
                            name="body"
                            rules={[{ required: true, message: "Body is required" }]}
                        >
                            {isPasteHtml ? (
                                <Input.TextArea
                                    rows={7}
                                    placeholder="<>...</>"
                                    disabled={STATUS == 1}
                                />
                            ) : STATUS == 1 ? (
                                <div dangerouslySetInnerHTML={{ __html: editorValue }}></div>
                            ) : (
                                <TextRichEditor
                                    value={editorValue}
                                    onChange={setEditorValue}
                                    placeholder={"Enter email body"}
                                    disabled={STATUS == 1}
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Footer" name="footer">
                            <Input.TextArea
                                rows={2}
                                placeholder="Enter email footer body"
                                disabled={STATUS == 1}
                            />
                        </Form.Item>
                    </Form>
                </MainCard>

                {STATUS != 1 && (
                    <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>
                        <Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "13px",
                                minWidth: "150px",
                            }}
                            onClick={() => HandleSave(1)}
                            size="large"
                        >
                            Save
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "13px",
                                minWidth: "150px",
                            }}
                            onClick={() => HandleSave(2)}
                            size="large"
                        >
                            Save as Draft
                        </Button>
                    </div>
                )}

                <Table
                    className="border rounded"
                    style={{ marginTop: 25 }}
                    dataSource={[]}
                    columns={EditActivityLeadsLogs()}
                />
            </div>
        </MainCard>
    );
};

const mapStateToProps = (state) => ({
    emailTemplates: state?.EmailTemplatesReducer?.emailTemplates,
    emailSettings: state?.AllEmailSettingReducer?.allEmailsetting?.data,
    activityDetail: state?.ActivityDetailRedc?.activityDetails
});

export default connect(mapStateToProps)(EmailActivityEdit);