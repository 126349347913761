// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ConfigProvider
    theme={{
      components: {
        Input: {
          colorBgContainer: '#fff', // Input background color
          colorBorder: '#D6E4FF', // Input border color
          colorText: '#0055A2',
          activeBorderColor: '#D6E4FF',
        },
        Select: {
          colorBgContainer: '#fff', // Select background color
          colorBorder: '#D6E4FF', // Select border color
          colorText: '#0055A2',
          activeBorderColor: '#D6E4FF',
        },
      },
      token: {
        // Seed Token
        colorPrimary: '#0055A2',
        colorBorder: '#D6E4FF',
        // Alias Token
      },
    }}
  >
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="black"
          progressStyle={{ background: '#0B3D58', height: '4px' }}
        />
      </ScrollTop>
    </ThemeCustomization>
  </ConfigProvider>
);

export default App;
