import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import {
    FetchAllCallScript,
    FetchEditActivity,
    FetchActivityDetails,
    GetAllUsers,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography, Modal, Form, Input } from "antd";
import { connect } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeads, EditActivityLeadsLogs } from "components/columns/columns";
import AddCallScriptPage from "pages/callscript/AddCallScriptPage";
import EditableFormFields from "./AddMultiQuestion";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";

const { Option } = Select;
const { Title } = Typography;

const CallActivityEdit = ({
    allCallscript,
    allTeamMembers,
    activityDetail,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(
        location?.state?.data?.template_id ?? null
    );
    const [EditActivityTemplate, setEditActivityTemplate] = useState({});
    const [leadsDataSource, setLeadsDataSource] = useState([]);
    const [agent, setAgent] = useState([]); // State for Agent selection
    const [additionalFields, setAdditionalFields] = useState([
        { id: 1, name: "", type: "Single Select", values: [], isEditing: false }
    ]);
    const [editorValue, setEditorValue] = useState(EditActivityTemplate?.name ?? '');
    const [scriptName, setScriptName] = useState(EditActivityTemplate?.description ?? '')
    const [form] = Form.useForm();
    useEffect(() => {
        dispatch(FetchAllCallScript({ setLoading: () => { }, params: {} }));
        dispatch(GetAllUsers());
        dispatch(FetchActivityDetails({ id: location.state?.data?.activity_id_specific }));
    }, []);

    useEffect(() => {
        if (Array.isArray(allCallscript.data)) {
            setTemplateOptions(allCallscript.data.map(v => ({ ...v, name: v.script })));
        }
    }, [allCallscript]);

    useEffect(() => {
        if (activityDetail) {
            setLeadsDataSource([...location?.state?.data?.leads, ...(activityDetail?.leads ? activityDetail?.leads : [])]);
        }
    }, [activityDetail]);

    const handleTemplateChange = (value) => {
        setSelectedTemplate(value);
    };

    useEffect(() => {
        if (selectedTemplate && templateOptions?.length !== 0) {
            let filterTemplate = templateOptions?.filter(
                (e) => e?.id === selectedTemplate
            );
            setEditActivityTemplate(filterTemplate[0]);

        }
    }, [selectedTemplate, templateOptions]);

    useEffect(() => {
        form.setFieldsValue({
            script: EditActivityTemplate.name,
            description: EditActivityTemplate.description
        })
        setEditorValue(EditActivityTemplate.description)
    }, [EditActivityTemplate])

    const handleNavigate = () => {
        navigate(-1);
    };

    const HandleSave = async (status_id) => {
        try {
            const val = await form.validateFields();
            if (selectedTemplate && EditActivityTemplate?.name === val.name && val.description === EditActivityTemplate.description) {

                if (status_id === 1) {
                    if (!selectedTemplate) {
                        toast.error("Template must be selected");
                        return;
                    }
                }

                const payload = {
                    activity_id: location?.state?.data?.activity_id_specific,
                    campaign_id: location?.state?.data?.campaign_id,
                    template_id: selectedTemplate,
                    status: status_id,
                    agent,
                    additionalFields
                };

                dispatch(FetchEditActivity({ fieldData: payload, isNavigate: handleNavigate }));
            } else {


            }
            console.log(val, 'validate')

        } catch (error) {
            console.log(error)
            if (error?.errorFields) {
                error?.errorFields?.forEach(v => {

                    toast.error(v?.errors?.[0])
                })
            }
        }


    };

    const STATUS = location.state?.data?.status;
    const agentOptions = Array.isArray(allTeamMembers?.users)
        ? allTeamMembers?.users.map(v => ({ label: v.name, value: v.id }))
        : [];

    return (
        <MainCard>
            <Form form={form} className='mt-4' layout='vertical'>

                <div>
                    <h3 style={{ marginBottom: "20px" }}>Edit Call Activity</h3>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ justifyContent: "left" }}>
                            <AddLeadsDrawer
                                leadsDataSource={leadsDataSource}
                                datacampaign={location?.state?.data}
                                handleNavigate={handleNavigate}
                                status={STATUS}
                            >
                                <Button
                                    style={{
                                        backgroundColor: "#0055A2",
                                        color: "#fff",
                                        fontFamily: "poppins",
                                        fontSize: "13px",
                                    }}
                                    size="large"
                                >
                                    <IoMdAddCircle color="#fff" size={24} />
                                    Add Leads
                                </Button>
                            </AddLeadsDrawer>
                        </div>
                        <div style={{ display: "flex", gap: 10 }}>
                            <div className="count-box">
                                <span>Total: </span>{location?.state?.data?.leads?.length}
                            </div>
                            <div className="count-box">
                                <span>New: </span>
                                {Array.isArray(activityDetail?.leads) ? activityDetail?.leads?.length : 0}
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: 20, display: "flex", gap: 20 }}>
                        <div>
                            <label style={{ display: "block", marginBottom: 5, fontSize: 13 }}>
                                Select Agent
                            </label>
                            <Select
                                placeholder="Select Agent"
                                style={{ width: 300 }}
                                value={agent}
                                disabled={STATUS == 1}
                                onChange={setAgent}
                                mode="multiple"
                            >
                                {agentOptions.map((agent) => (
                                    <Option key={agent.value} value={agent.value}>
                                        {agent.label}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div>
                            <label style={{ display: "block", marginBottom: 5, fontSize: 13 }}>
                                Select Script
                            </label>
                            <Select
                                placeholder="Select Template"
                                style={{ width: 300, }}
                                value={selectedTemplate}
                                disabled={STATUS == 1}
                                onChange={handleTemplateChange}
                            >
                                {templateOptions?.map((template) => (
                                    <Option key={template.id} value={template.id}>
                                        {template.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <Form.Item
                            name="script"
                            label="Script Name"
                            rules={[
                                { required: true, message: "Please input the script name!" },
                            ]}

                        >
                            <Input placeholder="Enter script name" value={scriptName} onChange={(e) => setScriptName(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            label="Call Script"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    validator: () => {
                                        return editorValue == '<p> </p>' || editorValue === '<p><br></p>' || editorValue == ''
                                            ? Promise.reject("Call script is required") : Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <TextRichEditor value={editorValue} onChange={setEditorValue} placeholder={"Call Script"} />
                        </Form.Item>
                        {/* <AddCallScriptPage EditActivityTemplate={EditActivityTemplate} /> */}
                    </div>

                    <div>
                        <h6 style={{ fontWeight: "900", color: "black", marginTop: 30 }}>
                            Leads Column Names:
                        </h6>
                        {EditActivityLeads()?.map((e) => (
                            <div key={e.key} style={{ marginBottom: 4 }}>
                                {e?.title}
                            </div>
                        ))}
                    </div>

                    <EditableFormFields
                        additionalFields={additionalFields}
                        setAdditionalFields={setAdditionalFields}
                    />

                    {STATUS != 1 && (
                        <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    minWidth: "150px",
                                }}
                                onClick={() => HandleSave(1)}
                                size="large"
                            >
                                Save
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    minWidth: "150px",
                                }}
                                onClick={() => HandleSave(2)}
                                size="large"
                            >
                                Save as Draft
                            </Button>
                        </div>
                    )}

                    <Table
                        className="border rounded"
                        style={{ marginTop: 25 }}
                        dataSource={[]}
                        columns={EditActivityLeadsLogs()}
                    />
                </div>
            </Form>
        </MainCard>
    );
};

const mapStateToProps = (state) => ({
    allCallscript: state?.AllCallScriptReducer?.allCallscript,
    allTeamMembers: state?.GetAllUsers?.getAllUsers,
    activityDetail: state?.ActivityDetailRedc?.activityDetails
});

export default connect(mapStateToProps)(CallActivityEdit);