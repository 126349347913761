import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { Pagination } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AddActivityDrawerRight from "components/drawer/AddActivityDrawer";
import { EditOutlined } from "@ant-design/icons";
import { dispatch } from "store/index";
import { FetchAllActivitiesOfCamp } from "store/action/index";
import { IoIosCall } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";
import { MdOutlineSms } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { TbEyeCog } from "react-icons/tb";
const AllCampaigns = ({ allActivities }) => {
  const [loading, setLoading] = useState(false);
  const [params, setparams] = useState({ page: 1, activities_id: 2 });
  const location = useLocation();
  const navigate = useNavigate();
  const activityRoutes = {
    SMS: '/activities/edit-sms-activity',
    Call: '/activities/edit-call-activity',
    Email: '/activities/edit-email-activity',
    WhatsApp: '/activities/edit-whatsapp-activity',
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          {record?.activities?.id == 2 ? (
            <IoIosCall style={{ fontSize: "22px" }} />
          ) : record?.activities?.id == 3 ? (
            <FaWhatsapp style={{ fontSize: "22px" }} />
          ) : record?.activities?.id === 5 ? (
            <MdEmail style={{ fontSize: "22px" }} />
          ) : record?.activities?.id == 4 ? (
            <MdOutlineSms style={{ fontSize: "22px" }} />
          ) : (
            ""
          )}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="text"
          style={{ color: "#1890ff" }}
          onClick={() => handleEdit(record)}
        >
          {record?.status != 1 ? (
            <EditOutlined style={{ fontSize: "18px" }} />
          ) : (
            <TbEyeCog style={{ fontSize: "18px" }} />
          )}
        </Button>
      ),
    },
  ];

  const handleEdit = (record) => {
    console.log('first')
    navigate(activityRoutes[record?.activities?.name], {
      state: {
        data: {
          ...location?.state,
          ...record,
          ...allActivities,
          activity_id_specific: record?.id,
          records: record,
        },
      },
    });
  };
  const handlePageChange = (page) => {
    setparams({ ...params, page });
  };
  useEffect(() => {
    dispatch(FetchAllActivitiesOfCamp({ id: location.state?.id }));
  }, []);

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  {location.state?.name}
                </span>
                <p
                  style={{
                    fontSize: "10px",
                    padding: "0px",
                    margin: "0px",
                    marginLeft: "4px",
                  }}
                >
                  {location.state?.start_date}
                </p>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <AddActivityDrawerRight data={location?.state}>
                  <Button
                    disableElevation
                    className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoMdAddCircle color="#0055A2" size={26} />
                    </span>
                    Add Activity
                  </Button>
                </AddActivityDrawerRight>
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      <>
        <MainCard>
          {true ? (
            <Spin spinning={loading}>
              <Table
                className="border rounded"
                style={{ marginTop: 25 }}
                dataSource={allActivities?.campaings_activities ?? []}
                columns={columns}
              />
            </Spin>
          ) : null}
        </MainCard>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allActivities: state?.CampaignAllActivitiesRedc?.campaignAllActivities,
  };
};

export default connect(mapStateToProps)(AllCampaigns);
