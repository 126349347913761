import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: HiOutlineUserGroup, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

// Define Leads menu based on permissions
const OmniCHannel = true
  ? {
    id: "omnichannel-group",
    title: "Omni Channel",
    icon: icons.allLeads,
    type: "group",
    children: [
      true && {
        id: "omni-channel",
        title: "Omni Channel",
        type: "item",
        url: "/omnichannel/omni-channel",
        icon: icons.allLeads,
        breadcrumbs: true,
      },
    ].filter(Boolean),
  }
  : null;

export default OmniCHannel;
