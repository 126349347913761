import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: HiOutlineUserGroup, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

const callscriptMenu = {
  id: "callscript-group",
  title: "Call",
  icon: icons.allLeads,
  type: "group",
  children: [
    {
      id: "all-call-script",
      title: "All Call Script",
      type: "item",
      url: "/call-script/all-call-script",
      icon: icons.allLeads,
      breadcrumbs: true,
    },
    {
      id: "all-call-manager",
      title: "Call Manager",
      type: "item",
      url: "/activities/call-manager",
      icon: icons.allLeads,
      breadcrumbs: true,
    },
  ].filter(Boolean),
};

export default callscriptMenu;
