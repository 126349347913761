import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Space,
  Form,
  Input,
  Steps,
  Select,
  DatePicker,
} from "antd";
import CsvUploaderLeads from "components/inputs/CsvUploaderLeads";
import { useSelector } from "react-redux";
import PaginatedSelect from "components/selects/SelectPagination";
import { dispatch } from "store/index";
import { CreateCampaign, FetchAllSelectLeads } from "store/action/index";

const DrawerRight = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const selector = useSelector((state) => state);
  const allMembersData = selector?.GetAllUsers?.getAllUsers?.users ?? [];
  const allLeadsData = selector?.AllLeadsSelectReducer?.allLeads?.data ?? [];

  const resetForm = () => {
    // Reset form fields
    form.resetFields();

    // Reset CSV data
    setDataSource([]);

    // Reset to first step
    setCurrentStep(0);

    // Reset initial activities
    form.setFieldsValue({
      activities: [{}],
    });
  };

  useEffect(() => {
    dispatch(FetchAllSelectLeads());
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      activities: [{}],
    });
  }, [form]);

  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const onClose = () => {
    resetForm();
    setOpen(false);
  };

  const onFinish = (values) => {
    const allValues = { ...form.getFieldValue(), "csv-leads": dataSource };
    allValues.start_date = new Date(allValues?.start_date?.$d)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-");
    allValues.end_date = allValues?.end_date?.$d
      ? new Date(allValues?.end_date?.$d)
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-")
      : null;
    dispatch(
      CreateCampaign({
        setLoading: setIsLoading,
        fieldData: allValues,
        setOpen: setOpen,
        onClose: () => {
          // Reset everything after successful submission
          resetForm();
          onClose();
        },
      })
    );
  };

  const next = async () => {
    try {
      await form.validateFields();
      setCurrentStep((prev) => prev + 1);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  const prev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const callAllLeadsApi = (setLoading, params) => {
    dispatch(FetchAllSelectLeads({ setLoading: setLoading, params: params }));
  };

  useEffect(() => { console.log("testingggggggggg", allLeadsData) }, [allLeadsData])

  const steps = [
    {
      title: "Basic Settings",
      description: "Campaign Settings",
      fields: (
        <>
          <div
            style={{
              display: "flex",
              gap: 10,
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your Name!" }]}
              style={{ width: "50%" }}
            >
              <Input className="p-2" />
            </Form.Item>
            <Form.Item
              label="Members"
              name="members"
              rules={[{ required: true, message: "Please select a value" }]}
              style={{ width: "50%" }}
            >
              <Select placeholder="Select Members" mode="multiple">
                {allMembersData.map((v) => (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="d-flex gap-2">
            <Form.Item
              style={{ width: "100%" }}
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Please select a start date!",
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                className="flex-1 p-2"
                style={{ width: "100%" }}
                inputReadOnly={true}
              />
            </Form.Item>

            <Form.Item
              style={{ width: "100%" }}
              label="End Date"
              name="end_date"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("start_date") <= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("End date must be after start date!")
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                className="p-2"
                format="YYYY-MM-DD"
                style={{ width: "100%" }}
                inputReadOnly={true}
              />
            </Form.Item>
          </div>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={4} placeholder="Enter email description" />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Import Data",
      description: "Campaign Leads/Data",
      fields: (
        <>
          <div className="py-2">
            <Form.Item
              label="Leads"
              name="leads"
              style={{ width: "100%", marginBottom: "0px" }}
            >
              <PaginatedSelect
                optKey={"name"}
                optValue={"id"}
                opt={allLeadsData}
                callApi={callAllLeadsApi}
                allData={selector?.AllLeadsSelectReducer?.allLeads ?? {}}
              />
            </Form.Item>
          </div>
          <CsvUploaderLeads
            setDataSource={setDataSource}
            dataSource={dataSource}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }} onClick={showLargeDrawer}>
        {children}
      </div>
      <Drawer
        title="Add Campaign"
        zIndex={10000}
        width={1200}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
      >
        <div className="container-fluid mt-1">
          <div className="row">
            <Steps
              current={currentStep}
              items={steps.map((step) => ({
                title: step.title,
                description: step.description,
              }))}
              style={{ marginRight: "20px" }}
            />

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: "100%" }}
              onFinish={onFinish}
              autoComplete="off"
              className="mt-3"
            >
              {steps[currentStep].fields}

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Space>
                  {currentStep > 0 && <Button onClick={prev}>Previous</Button>}
                  {currentStep < steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={next}
                      style={{ marginTop: "10px" }}
                    >
                      Next
                    </Button>
                  )}
                  {currentStep === steps.length - 1 && (
                    <Button
                      style={{ marginTop: "10px" }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerRight;
