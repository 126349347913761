import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import {
    FetchAllWhatsappTemplates,
    FetchCreateWhatsAppSetting,
    FetchEditActivity,
    FetchActivityDetails,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography } from "antd";
import { connect } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeadsLogs } from "components/columns/columns";
import CreateWhstappTemplate from "../whatsapp-templates/create-edit-whatsapptemplate";

const { Option } = Select;
const { Title } = Typography;

const WhatsAppActivityEdit = ({
    whatsappTemplates,
    whatsappSettings,
    activityDetail,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(
        location?.state?.data?.template_id ?? null
    );
    const [selectedProvider, setSelectedProvider] = useState(
        location?.state?.data?.setting_id ?? null
    );
    const [EditActivityTemplate, setEditActivityTemplate] = useState({});
    const [leadsDataSource, setLeadsDataSource] = useState([]);

    useEffect(() => {
        dispatch(FetchAllWhatsappTemplates());
        dispatch(FetchCreateWhatsAppSetting({ setLoading: () => { } }));
        dispatch(FetchActivityDetails({ id: location.state?.data?.activity_id_specific }));
    }, []);

    useEffect(() => {
        setTemplateOptions(whatsappTemplates);
    }, [whatsappTemplates]);

    useEffect(() => {
        if (activityDetail) {
            setLeadsDataSource([...location?.state?.data?.leads, ...(activityDetail?.leads ? activityDetail?.leads : [])]);
        }
    }, [activityDetail]);

    const handleTemplateChange = (value) => {
        setSelectedTemplate(value);
    };

    useEffect(() => {
        if (selectedTemplate && templateOptions?.length !== 0) {
            let filterTemplate = templateOptions?.filter(
                (e) => e?.id === selectedTemplate
            );
            setEditActivityTemplate(filterTemplate[0]);
        }
    }, [selectedTemplate, templateOptions]);

    const handleNavigate = () => {
        navigate(-1);
    };

    const HandleSave = (status_id) => {
        if (status_id === 1) {
            if (!selectedTemplate) {
                toast.error("Template must be selected");
                return;
            }
            if (!selectedProvider) {
                toast.error("Provider must be selected");
                return;
            }
        }

        const payload = {
            activity_id: location?.state?.data?.activity_id_specific,
            campaign_id: location?.state?.data?.campaign_id,
            template_id: selectedTemplate,
            setting_id: selectedProvider,
            status: status_id,
        };

        dispatch(FetchEditActivity({ fieldData: payload, isNavigate: handleNavigate }));
    };

    const STATUS = location.state?.data?.status;

    return (
        <MainCard>
            <div>
                <h3 style={{ marginBottom: "20px" }}>Edit WhatsApp Activity</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "left" }}>
                        <AddLeadsDrawer
                            leadsDataSource={leadsDataSource}
                            datacampaign={location?.state?.data}
                            handleNavigate={handleNavigate}
                            status={STATUS}
                        >
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                }}
                                size="large"
                            >
                                <IoMdAddCircle color="#fff" size={24} />
                                Add Leads
                            </Button>
                        </AddLeadsDrawer>
                    </div>
                    <div style={{ display: "flex", gap: 10 }}>
                        <div className="count-box">
                            <span>Total: </span>{location?.state?.data?.leads?.length}
                        </div>
                        <div className="count-box">
                            <span>New: </span>
                            {Array.isArray(activityDetail?.leads) ? activityDetail?.leads?.length : 0}
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 30,display:"flex", gap:20  }}>
                    <div style={{ marginBottom: 10 }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Template</label>
                        <Select
                            placeholder="Select Template"
                            style={{ width: 300 }}
                            value={selectedTemplate}
                            disabled={STATUS == 1}
                            onChange={handleTemplateChange}
                        >
                            {templateOptions?.map((template) => (
                                <Option key={template.id} value={template.id}>
                                    {template.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{ marginBottom: 10 }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Provider</label>
                        <Select
                            placeholder="Provider"
                            style={{ width: 300 }}
                            value={selectedProvider}
                            disabled={STATUS == 1}
                            onChange={(v) => setSelectedProvider(v)}
                        >
                            {whatsappSettings?.map((setting) => (
                                <Option key={setting.id} value={setting.id}>
                                    {setting.sender_name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <div style={{ marginTop: 20 }}>
                    <CreateWhstappTemplate
                        isDisabled={true}
                        EditActivityTemplate={EditActivityTemplate}
                    />
                </div>

                {STATUS != 1 && (
                    <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>
                        <Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "13px",
                                minWidth: "150px",
                            }}
                            onClick={() => HandleSave(1)}
                            size="large"
                        >
                            Save
                        </Button>
                        <Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "13px",
                                minWidth: "150px",
                            }}
                            onClick={() => HandleSave(2)}
                            size="large"
                        >
                            Save as Draft
                        </Button>
                    </div>
                )}

                <Table
                    className="border rounded"
                    style={{ marginTop: 25 }}
                    dataSource={[]}
                    columns={EditActivityLeadsLogs()}
                />
            </div>
        </MainCard>
    );
};

const mapStateToProps = (state) => ({
    whatsappTemplates: state?.WhatsappTemplatesReducer?.whatsappTemplates,
    whatsappSettings: state?.AllWhatsappSettingReducer?.allWhatsappsetting?.data,
    activityDetail: state?.ActivityDetailRedc?.activityDetails
});

export default connect(mapStateToProps)(WhatsAppActivityEdit);