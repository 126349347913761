import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Spin } from "antd";
import MainCard from "components/MainCard";
import CustomInput from "components/inputs/CustomInput";
import { allFieldsLead } from "components/all-forms-fields/all-forms-fields";
import { FetchGetAllTags, CreateLeads } from "store/action/index";
import { dispatch } from "store/index";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddLeads = ({ allTagsData }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FetchGetAllTags({ setLoading: setIsLoading }));
  }, []);

  const navigateToAllLead = () => {
    navigate("/leads/all-leads");
  };
  const onFinish = (values) => {
    dispatch(
      CreateLeads({
        setLoading: setIsLoading,
        fieldData: values,
        isNavigate: navigateToAllLead,
      })
    );
  };

  const tag = Array.isArray(allTagsData)
    ? allTagsData.map((v) => ({ label: v.name, value: v.id }))
    : [];

  return (
    <MainCard>
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            {allFieldsLead(tag).map((field, index) => (
              <Col span={12} key={index}>
                <CustomInput {...field} />
              </Col>
            ))}
          </Row>

          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Lead
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainCard>
  );
};

const mapStateToProps = (state) => {
  return {
    allTagsData: state?.AllGetTagsReducer?.alltags?.data,
  };
};
export default connect(mapStateToProps)(AddLeads);
