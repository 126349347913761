export const allFieldsLead = (tagsOpt) => {
  return [
    {
      label: "Name",
      name: "name",
      placeholder: "Enter your name",
      rules: [{ required: true, message: "Please input your name!" }],
      type: "text",
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Enter your email",
      type: "text",
    },
    {
      label: "Phone",
      name: "phone",
      placeholder: "Enter your phone number",
      type: "number",
    },
    {
      label: "Tags",
      name: "tags",
      placeholder: "Select or type tags",
      type: "select",
      multiple: "multiple",
      options: tagsOpt ?? [],
    },
  ];
};
