import { lazy, useEffect } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import {
  can_add_roles,
  can_edit_roles,
  can_view_roles,
  can_add_users,
  can_edit_users,
  can_view_users,
  can_add_permissions,
} from "helper/permissionsCheck";
import AllCampaigns from "pages/campaigns/AllCampaigns";
import CallLogs from "pages/allLogs/CallLogs";
import SmsLogs from "pages/allLogs/SmsLogs";
import EmailLogs from "pages/allLogs/EmailLogs";
import WhatsAppLogs from "pages/allLogs/WhatsAppLogs";

import AllLeads from "pages/leads/AllLeads";
import AllTags from "../pages/tags/allTags";
import AllCallScript from "../pages/callscript/allcallscript";
import AllActivities from "../pages/activities/allactivities";
import AddLeads from "pages/leads/CreateLeads";
import CallManager from "pages/activities/CallManager";
import CRMLeadDetails from "pages/activities/CallLeads";
import AllEmailTemplates from "pages/email-templates/AllEmailTemplates";
import WhatsappTemplate from "pages/whatsapp-templates/whatsapp-template";
import CreateWhstappTemplate from "pages/whatsapp-templates/create-edit-whatsapptemplate.jsx";
import SmsTemplate from "pages/sms-templates/Sms-template";
import AddEmailSetting from "pages/settings/email-setting/AddEmailSetting";
import AddSMSSetting from "pages/settings/sms-setting/AddSMSSetting";
import AllSMSSetting from "pages/settings/sms-setting/AllSMSSetting";
import AllEmailSetting from "pages/settings/email-setting/AllEmailSetting";
import AllWhatsAppSettings from "pages/settings/whatsapp-setting/AllWhatsAppSettings";
import AddWhatsappSetting from "pages/settings/whatsapp-setting/AddWhatsAppSetting";
import AllTeams from "pages/teams/AllTeams";
import AddTeam from "pages/teams/AddTeam";
import EmailActivityEdit from "pages/activities/EmailActivityEdit";
import WhatsappActivityEdit from "pages/activities/WhatsappActivityEdit";
import SMSActivityEdit from "pages/activities/SMSActivityEdit";
import CallActivityEdit from "pages/activities/CallActivityEdit";

const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
const AllRoles = Loadable(lazy(() => import("pages/roles/allroles")));
const ChatApp = Loadable(lazy(() => import("pages/whatsapp/whatsapp")));
const OmnichannelInterface = Loadable(
  lazy(() => import("pages/omnichannel/omnichannel"))
);
const ActivityEdit = Loadable(
  lazy(() => import("pages/activities/activity-edit"))
);

const AddWhatsAppSetting = Loadable(
  lazy(() => import("pages/settings/whatsapp-setting/AddWhatsAppSetting"))
);

const RegisterUser = Loadable(
  lazy(() => import("pages/user-management/registerUser"))
);
const PermissionsAssign = Loadable(
  lazy(() => import("pages/permissions/assign"))
);
const AllUsers = Loadable(lazy(() => import("pages/user-management/allusers")));

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray =
  decryptedData && JSON.parse(decryptedData)?.user_type;

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <DashboardDefault />,
        },
        {
          path: "dashboard",
          element: <DashboardDefault />,
        },
        {
          path: "*",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "settings",
      element: <MainLayout />,
      children: [
        {
          path: "add-whatsapp-setting",
          element: <AddWhatsAppSetting />,
        },

        {
          path: "all-whatsapp-setting",
          element: <AllWhatsAppSettings />,
        },
        {
          path: "add-whatsapp-setting",
          element: <AddWhatsappSetting />,
        },
        {
          path: "all-email-setting",
          element: <AllEmailSetting />,
        },
        {
          path: "add-email-setting",
          element: <AddEmailSetting />,
        },
        {
          path: "all-sms-setting",
          element: <AllSMSSetting />,
        },
        {
          path: "add-sms-setting",
          element: <AddSMSSetting />,
        },
      ].filter(Boolean),
    },
    true
      ? {
        path: "campaigns",
        element: <MainLayout />,
        children: [
          {
            path: "all-campaigns",
            element: <AllCampaigns />,
          },
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "all-logs",
        element: <MainLayout />,
        children: [
          {
            path: "call-log",
            element: <CallLogs />,
          },
          {
            path: "sms-log",
            element: <SmsLogs />,
          },
          {
            path: "email-log",
            element: <EmailLogs />,
          },
          {
            path: "whatsapp-log",
            element: <WhatsAppLogs />,
          },
        ].filter(Boolean),
      }
      : null,
    true
      ? {
        path: "omnichannel",
        element: <MainLayout />,
        children: [
          {
            path: "omni-channel",
            element: <OmnichannelInterface />,
          },
        ].filter(Boolean),
      }
      : null,
    true
      ? {
        path: "tags",
        element: <MainLayout />,
        children: [
          {
            path: "all-tags",
            element: <AllTags />,
          },
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "call-script",
        element: <MainLayout />,
        children: [
          {
            path: "all-call-script",
            element: <AllCallScript />,
          },
        ].filter(Boolean),
      }
      : null,
    true
      ? {
        path: "activities",
        element: <MainLayout />,
        children: [
          {
            path: "all-activities",
            element: <AllActivities />,
          },
          {
            path: "edit_activity",
            element: <ActivityEdit />,
          },
          {
            path: "edit-email-activity",
            element: <EmailActivityEdit />,
          },
          {
            path: "edit-whatsapp-activity",
            element: <WhatsappActivityEdit />,
          },

          {
            path: "edit-sms-activity",
            element: <SMSActivityEdit />,
          },
          {
            path: "edit-call-activity",
            element: <CallActivityEdit />,
          },
          {
            path: "call-manager",
            element: <CallManager />,
          },
          {
            path: "call-leads",
            element: <CRMLeadDetails />,
          },
          {
            path: "campaign-whatsapp-conversations",
            element: <ChatApp />,
          },
        ].filter(Boolean),
      }
      : null,
    true
      ? {
        path: "leads",
        element: <MainLayout />,
        children: [
          {
            path: "all-leads",
            element: <AllLeads />,
          },
          {
            path: "add-lead",
            element: <AddLeads />,
          },
        ].filter(Boolean),
      }
      : null,
    true
      ? {
        path: "templates",
        element: <MainLayout />,
        children: [
          {
            path: "email-templates",
            element: <AllEmailTemplates />,
          },
          {
            path: "whatsapp-templates",
            element: <WhatsappTemplate />,
          },

          {
            path: "create-whatsapp-templates",
            element: <CreateWhstappTemplate />,
          },
          {
            path: "sms-templates",
            element: <SmsTemplate />,
          },
        ].filter(Boolean),
      }
      : null,

    can_add_roles || can_edit_roles || can_view_roles
      ? {
        path: "roles",
        element: <MainLayout />,
        children: [
          {
            path: "all-roles",
            element: <AllRoles />,
          },
        ].filter(Boolean),
      }
      : null,

    can_add_permissions
      ? {
        path: "permissions",
        element: <MainLayout />,
        children: [
          can_add_permissions
            ? {
              path: "all-permissions",
              element: <PermissionsAssign />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "teams",
        element: <MainLayout />,
        children: [
          can_add_users || can_edit_users || can_view_users
            ? {
              path: "all-teams",
              element: <AllTeams />,
            }
            : null,
          can_add_users || can_edit_users || can_view_users
            ? {
              path: "add-team",
              element: <AddTeam />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,
    true
      ? {
        path: "users",
        element: <MainLayout />,
        children: [
          can_add_users || can_edit_users || can_view_users
            ? {
              path: "all-users",
              element: <AllUsers />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "users",
        element: <MainLayout />,
        children: [
          can_add_users
            ? {
              path: "register-users",
              element: <RegisterUser />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,
  ].filter(Boolean),
};

export default MainRoutes;
