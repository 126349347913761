import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchCreateWhatsAppSetting } from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { AllWhatsappSettingCOlumns } from "components/columns/columns";

// ==============================|| WHATSAPP SETTINGS - MANAGEMENT ||============================== //

const AllWhatsAppSetting = ({ allWhatsappsetting }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FetchCreateWhatsAppSetting({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    console.log("whatsapp", allWhatsappsetting);
  }, [allWhatsappsetting]);

  const columns = AllWhatsappSettingCOlumns();

  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span>
          <IconButton
            className="mx-2"
            type="primary"
            onClick={() => {
              navigate("/settings/add-whatsapp-setting", { state: record });
            }}
          >
            <EditOutlined />
          </IconButton>
        </span>
      );
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "67px",
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All WhatsApp Settings
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Link to={"/settings/add-whatsapp-setting"}>
            <Button
              disableElevation
              className="px-2"
              size="large"
              type="submit"
            >
              <span className="mx-2">
                <IoMdAddCircle color="#00529D" size={26} />
              </span>
              Add WhatsApp Setting
            </Button>
          </Link>
        </Grid>
      </div>

      <MainCard>
        <Spin spinning={loading}>
          <Table
            className="border rounded"
            style={{ marginTop: 25 }}
            dataSource={allWhatsappsetting?.data ?? []}
            columns={columns}
          />
        </Spin>
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allWhatsappsetting: state.AllWhatsappSettingReducer.allWhatsappsetting,
  };
};

export default connect(mapStateToProps)(AllWhatsAppSetting);
