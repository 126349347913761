import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { Pagination } from "antd";
import { dispatch } from "store/index";
import { FetchAllCompaigns ,FetchCallActivity} from "store/action/index";
import CampaignCard from "components/cards/statistics/CallManagerCard";

const campaigns = [
  {
    id: 1,
    title: "Live Event Campaign 1",
    members: 3,
    progress: 9,
    remainingLeads: "4/44",
    lastActioner: "Admin",
    startedOn: "10-09-2024 06:32 pm",
  },
  {
    id: 2,
    title: "Webinar Campaign",
    members: 2,
    progress: 50,
    remainingLeads: "22/44",
    lastActioner: "User1",
    startedOn: "12-09-2024 08:00 am",
  },

  {
    id: 2,
    title: "Webinar Campaign",
    members: 2,
    progress: 50,
    remainingLeads: "22/44",
    lastActioner: "User1",
    startedOn: "12-09-2024 08:00 am",
  },
  {
    id: 2,
    title: "Webinar Campaign",
    members: 2,
    progress: 50,
    remainingLeads: "22/44",
    lastActioner: "User1",
    startedOn: "12-09-2024 08:00 am",
  },
  {
    id: 2,
    title: "Webinar Campaign",
    members: 2,
    progress: 50,
    remainingLeads: "22/44",
    lastActioner: "User1",
    startedOn: "12-09-2024 08:00 am",
  },
];

const AllCampaigns = ({activityCall}) => {
  const [loading, setLoading] = useState(true);
  const [params, setparams] = useState({ page: 1, activity_id: 2 });

  useEffect(() => {
    setLoading(true);
    dispatch(FetchCallActivity());
  }, [params]);

  const handlePageChange = (page) => {
    setparams({ ...params, page });
  };

  useEffect(()=>{
    console.log("checkingData",activityCall?.data)
  },[activityCall])

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  Call Manager
                </span>
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      <>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}
        >
          {Array.isArray(activityCall?.data) ? (
            activityCall?.data.map((campaign) => (
              <CampaignCard key={campaign.id} campaign={campaign} />
            ))
          ) : (
            <p>No Data</p>
          )}
        </div>
        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={activityCall?.meta?.current_page || 1}
            total={activityCall?.meta?.total || 0}
            pageSize={activityCall?.meta?.per_page || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activityCall: state?.ActivityCallRedc?.activityCall,
  };
};

export default connect(mapStateToProps)(AllCampaigns);
