import shop from "../shop/shop";
import * as types from "../constant/ActionType";

export const fssetchsogsinUser = (data) => ({
  type: types.FETCH_LOGIN_DATA,
  data,
});
export const ForgetPasswordz = (data) => ({
  type: types.FORGET_PASSWORD,
  data,
});

export const creatassignchildpermi = (data) => ({
  type: types.ALL_ASSIGN_PERMISSIONS,
  data,
});

export const specificroless = (data) => ({
  type: types.CREATE_ROLE,
  data,
});

export const Childassigpermissions = (data) => ({
  type: types.ASSIGN_PERMISSIONS,
  data,
});

export const childALLPermisssions = (data) => ({
  type: types.GETALL_PERMISSION,
  data,
});
export const rolePermissions = (data) => ({
  type: types.ROLE_PERMISSIONS,
  data,
});

export const contantAllviewroless = (data) => ({
  type: types.VIEW_ROLES,
  data,
});
export const viewOfAllUsers = (data) => ({
  type: types.VIEW_USERS,
  data,
});

export const updateStatusUser = (data) => ({
  type: types.UPDATE_STATUS_USERS,
  data,
});

export const edituserChild = (data) => ({
  type: types.EDIT_USER,
  data,
});

export const allcount = (data) => ({
  type: types.All_COUNTS,
  data,
});

export const PostChangePassword = (data) => ({
  type: types.CHANGE_PASSWORD,
  data,
});

export const createAddUsers = (data) => ({
  type: types.CREATE_ADD_USERS,
  data,
});
export const editUpdateUser = (data) => ({
  type: types.EDIT_UPDATE_USERS,
  data,
});
export const getAllUsers = (data) => ({
  type: types.GET_ALL_USERS,
  data,
});
export const CreateAddUsers = (data) => (dispatch) => {
  shop.CreateAddUsers(data, (e) => {
    dispatch(createAddUsers(e, data));
  });
};
export const EditUpdateUser = (data) => (dispatch) => {
  shop.EditUpdateUsers(data, (e) => {
    dispatch(editUpdateUser(e, data));
  });
};
export const GetAllUsers = (data) => (dispatch) => {
  shop.GetAllUsers(data, (e) => {
    dispatch(getAllUsers(e, data));
  });
};

export const fetchLoginUser = (data) => (dispatch) => {
  shop.fetchLoginUser(data, (e) => {
    dispatch(fssetchsogsinUser(e, data));
  });
};
export const ForgetPassword = (data) => (dispatch) => {
  shop.ForgetPassword(data, (e) => {
    dispatch(ForgetPasswordz(e, data));
  });
};
export const VerifyOTP = (data) => (dispatch) => {
  shop.VerifyOTP(data, (e) => {
    dispatch(ForgetPasswordz(e, data));
  });
};

export const CreateFetchRolee = (data) => (dispatch) => {
  shop.CreateFetchRolee(data, (e) => {
    dispatch(specificroless(e, data));
  });
};
export const EditRole = (data) => (dispatch) => {
  shop.EditRole(data, (e) => {
    dispatch(specificroless(e, data));
  });
};

export const FetchAllUsersView = (data) => (dispatch) => {
  shop.FetchAllUsersView(data, (e) => {
    dispatch(viewOfAllUsers(e, data));
  });
};
export const FetchAllRoleView = (data) => (dispatch) => {
  shop.FetchAllRoleView(data, (e) => {
    dispatch(contantAllviewroless(e, data));
  });
};

export const AssignPermissionsShop = (data) => (dispatch) => {
  shop.AssignPermissionsShop(data, (e) => {
    dispatch(Childassigpermissions(e, data));
  });
};

export const getAllPermissions = (data) => (dispatch) => {
  shop.getAllPermissions(data, (e) => {
    dispatch(childALLPermisssions(e, data));
  });
};

export const GetRolePermissions = (data) => (dispatch) => {
  shop.GetRolePermissions(data, (e) => {
    dispatch(rolePermissions(e, data));
  });
};

export const HandleEditUserApi = (data) => (dispatch) => {
  shop.HandleEditUserApi(data, (e) => {
    dispatch(edituserChild(e, data));
  });
};
export const AllCounts = (data) => (dispatch) => {
  shop.GetAllCounts(data, (e) => {
    dispatch(allcount(e, data));
  });
};

export const ChangePassword = (data) => (dispatch) => {
  shop.ChangePassword(data, (e) => {
    dispatch(PostChangePassword(e, data));
  });
};

///////// <<<   COMPAIGNS CRUD  >>> /////////

export const compaignsRedc = (data) => ({
  type: types.ALL_COMPAIGNS,
  data,
});
export const createCampaignRedc = (data) => ({
  type: types.CREATE_COMPAIGNS,
  data,
});

export const FetchAllCompaigns = (data) => (dispatch) => {
  shop.HitCompaignsApi(data, (e) => {
    dispatch(compaignsRedc(e, data));
  });
};

export const CreateCampaign = (data) => (dispatch) => {
  shop.CallEngineCreateCampaign(data, (e) => {
    dispatch(createCampaignRedc(e, data));
  });
};

// ________________________ ALL LOGS CRUD START ________________________________
export const getcallLogs = (data) => ({
  type: types.ALL_CALL_LOGS,
  data,
});

export const FetchAllCallLogs = (data) => (dispatch) => {
  shop.CallLogsGetApi(data, (e) => {
    dispatch(getcallLogs(e, data));
  });
};

export const getsmsLogs = (data) => ({
  type: types.ALL_SMS_LOGS,
  data,
});

export const FetchAllSmSLogs = (data) => (dispatch) => {
  shop.SmSLogsGetApi(data, (e) => {
    dispatch(getsmsLogs(e, data));
  });
};

export const getemailLogs = (data) => ({
  type: types.ALL_EMAIL_LOGS,
  data,
});

export const FetchAllEmailLogs = (data) => (dispatch) => {
  shop.EmailLogsGetApi(data, (e) => {
    dispatch(getemailLogs(e, data));
  });
};

export const getwhatsappLogs = (data) => ({
  type: types.ALL_WHATSAPP_LOGS,
  data,
});

export const FetchAllWhatsAppLogs = (data) => (dispatch) => {
  shop.WhatsAppLogsGetApi(data, (e) => {
    dispatch(getwhatsappLogs(e, data));
  });
};

// ________________________ ALL LOGS CRUD END ________________________________

// ________________________ LEADS ACTIVITY CRUD START ________________________________

// ________________________ LEADS ACTIVITY CRUD STENDART ________________________________
export const addCreateLeadActivity = (data) => ({
  type: types.ADD_LEADS_ACTIVITY,
  data,
});

export const callActivity = (data) => ({
  type: types.CALL_ACTIVITY,
  data,
});

export const AddLeadActivity = (data) => (dispatch) => {
  shop.createLeadActivities(data, (e) => {
    dispatch(addCreateLeadActivity(e, data));
  });
};
export const FetchCallActivity = (data) => (dispatch) => {
  shop.getAllActivity(data, (e) => {
    dispatch(callActivity(e, data));
  });
};
// ________________________ LEADS CRUD START ________________________________

export const AllleadsRedc = (data) => ({
  type: types.ALL_LEADS,
  data,
});
export const AllSelectleadsRedc = (data) => ({
  type: types.ALL_LEADS_SELECT,
  data,
});
export const createLeadRedc = (data) => ({
  type: types.CREATE_LEADS,
  data,
});
export const detailsleads = (data) => ({
  type: types.LEADS_DETAILS,
  data,
});

export const FetchAllDeatilsLeads = (data) => (dispatch) => {
  shop.CallEngineDetailLeadsAllData(data, (e) => {
    dispatch(detailsleads(e, data));
  });
};

// ---------
export const FetchAllLeads = (data) => (dispatch) => {
  shop.GetAllLeads(data, (e) => {
    dispatch(AllleadsRedc(e, data));
  });
};

export const FetchAllSelectLeads = (data) => (dispatch) => {
  shop.GetAllLeads(data, (e) => {
    dispatch(AllSelectleadsRedc(e, data));
  });
};

export const CreateLeads = (data) => (dispatch) => {
  shop.CallEngineCreateLeads(data, (e) => {
    dispatch(createLeadRedc(e, data));
  });
};
// ________________________ TAGS CRUD END ________________________________

export const alltagsDis = (data) => ({
  type: types.ALL_TAGS,
  data,
});

export const createtagsDis = (data) => ({
  type: types.CREATE_TAGS,
  data,
});

export const edittagschild = (data) => ({
  type: types.TAGS_EDIT,
  data,
});

export const FetchEditTags = (data) => (dispatch) => {
  shop.CallEngineEditTags(data, (e) => {
    dispatch(edittagschild(e, data));
  });
};

export const FetchCreateAllTags = (data) => (dispatch) => {
  shop.CallEngineCrTags(data, (e) => {
    dispatch(createtagsDis(e, data));
  });
};

export const FetchGetAllTags = (data) => (dispatch) => {
  shop.CallEngineTags(data, (e) => {
    dispatch(alltagsDis(e, data));
  });
};

// _______________ ACTIVITIES START _______________
export const allActivitiesRedc = (data) => ({
  type: types.ALL_ACTIVITES,
  data,
});

export const FetchAllActivities = (data) => (dispatch) => {
  shop.CallEngineActivities(data, (e) => {
    dispatch(allActivitiesRedc(e, data));
  });
};
// _______________ ACTIVITIES END _______________
// _______________ EmailTemplates START _______________
export const allEmailTemplatesRedc = (data) => ({
  type: types.ALL_EMAIL_TEMPLATES,
  data,
});

export const allWhtspTemplates = (data) => ({
  type: types.CREATE_WHATSAPP_TEMPLATE,
  data,
});
export const addEmailTemplatesRedc = (data) => ({
  type: types.ADD_EMAIL_TEMPLATE,
  data,
});
export const editEmailTemplatesRedc = (data) => ({
  type: types.EDIT_EMAIL_TEMPLATE,
  data,
});

export const FetchAllEmailTemplates = (data) => (dispatch) => {
  shop.CallEngineEmailTemplates(data, (e) => {
    dispatch(allEmailTemplatesRedc(e, data));
  });
};
export const AddEmailTemplate = (data) => (dispatch) => {
  shop.CallEngineAddEmailTemplates(data, (e) => {
    dispatch(addEmailTemplatesRedc(e, data));
  });
};

export const deleteEmailtemplates = (data) => ({
  type: types.DELETE_EMAIL_TEMPLATE,
  data,
});

export const FetchDeleteEmailTemplate = (data) => (dispatch) => {
  shop.CallEngineDeleteEmailTemplate(data, (e) => {
    dispatch(deleteEmailtemplates(e, data));
  });
};

export const AddWhatsappTemplate = (data) => (dispatch) => {
  shop.CallEngineAddWhatsappTemplates(data, (e) => {
    dispatch(allWhtspTemplates(e, data));
  });
};

export const EditEmailTemplate = (data) => (dispatch) => {
  shop.CallEngineEditEmailTemplates(data, (e) => {
    dispatch(editEmailTemplatesRedc(e, data));
  });
};
// _______________ Whstapp Templates END _______________

export const allWhstappTemplatesRedc = (data) => ({
  type: types.ALL_WHATSAPP_TEMPLATES,
  data,
});

export const FetchAllWhatsappTemplates = (data) => (dispatch) => {
  shop.CallEngineWhatsappTemplates(data, (e) => {
    dispatch(allWhstappTemplatesRedc(e, data));
  });
};

export const allemailsettingchild = (data) => ({
  type: types.ALL_EMAIL_SETTING,
  data,
});

export const FetchAllEmailSettings = (data) => (dispatch) => {
  shop.CallEngineEmailSettings(data, (e) => {
    dispatch(allemailsettingchild(e, data));
  });
};

export const childactioncreateemailsetting = (data) => ({
  type: types.CREATE_EMAIL_SETTING,
  data,
});
export const updateemailsettingchild = (data) => ({
  type: types.UPDATE_EMAIL_SETTING,
  data,
});

export const FetchUpdateEmailSetting = (data) => (dispatch) => {
  shop.CallEngineUpdateEmailSetting(data, (e) => {
    dispatch(updateemailsettingchild(e, data));
  });
};

export const FetchCreateEmailSetting = (data) => (dispatch) => {
  shop.CallEngineAddEmailSetting(data, (e) => {
    dispatch(childactioncreateemailsetting(e, data));
  });
};

export const childactioncreatewhatsappsetting = (data) => ({
  type: types.CREATE_WHATSAPP_SETTING,
  data,
});

export const FetchCreateWhatsAppSetting = (data) => (dispatch) => {
  shop.CallEngineAddWhatsAppSetting(data, (e) => {
    dispatch(childactioncreatewhatsappsetting(e, data));
  });
};
export const addnewwhstappsettings = (data) => ({
  type: types.ADD_NEW_WHATSAPP_SETTING,
  data,
});

export const FetchCreateNewWhatsappSetting = (data) => (dispatch) => {
  shop.CallEngineCreateNewWhatsappSetting(data, (e) => {
    dispatch(addnewwhstappsettings(e, data));
  });
};

export const dispatchSmssetting = (data) => ({
  type: types.ALL_SMS_SETTING,
  data,
});

export const FetchSmSSettings = (data) => (dispatch) => {
  shop.CallEngineSmsSettings(data, (e) => {
    dispatch(dispatchSmssetting(e, data));
  });
};

// ___________________ SMS TEMPLATES CRUD START  ____________________________
export const allSmSTemplatesRedc = (data) => ({
  type: types.ALL_SMS_TEMPLATES,
  data,
});

export const FetchAllSMSTemplates = (data) => (dispatch) => {
  shop.SmsTemplates(data, (e) => {
    dispatch(allSmSTemplatesRedc(e, data));
  });
};

export const crcSmSTemplatesRedc = (data) => ({
  type: types.CREATE_SMS_TEMPLATES,
  data,
});

export const CreateAllSMSTemplates = (data) => (dispatch) => {
  shop.SmsCrcTemplates(data, (e) => {
    dispatch(crcSmSTemplatesRedc(e, data));
  });
};

export const editSmSTemplatesRedc = (data) => ({
  type: types.EDIT_SMS_TEMPLATES,
  data,
});

export const EditAllSMSTemplates = (data) => (dispatch) => {
  shop.SmsEditTemplates(data, (e) => {
    dispatch(editSmSTemplatesRedc(e, data));
  });
};

// ___________________ SMS TEMPLATES CRUD END  ____________________________

// ___________________ TEAMS CRUD START  ____________________________
export const all_teams = (data) => ({
  type: types.ALL_TEAMS,
  data,
});
export const FetchAllTeams = (data) => (dispatch) => {
  shop.CallEngineAllTeams(data, (e) => {
    dispatch(all_teams(e, data));
  });
};
export const add_teams = (data) => ({
  type: types.ADD_TEAMS,
  data,
});
export const update_team = (data) => ({
  type: types.UPDATE_TEAMS,
  data,
});
export const CreateTeam = (data) => (dispatch) => {
  shop.CallEngineCreateTeams(data, (e) => {
    dispatch(add_teams(e, data));
  });
};

export const UpdateTeam = (data) => (dispatch) => {
  shop.CallEngineUpdateTeams(data, (e) => {
    dispatch(update_team(e, data));
  });
};
// ___________________ TEAMS CRUD END  ____________________________
// ___________________ ACTIVITY CRUD START  ____________________________

export const createactivitychild = (data) => ({
  type: types.CREATE_ACTIVITY,
  data,
});
export const allActivitiesOfCampaign = (data) => ({
  type: types.ALL_CAMP_ACTIVITY,
  data,
});
export const activityDetail = (data) => ({
  type: types.ACTIVITY_DETAILS,
  data,
});

export const FetchAddActivity = (data) => (dispatch) => {
  shop.CallEngineCreateAddActivity(data, (e) => {
    dispatch(createactivitychild(e, data));
  });
};

export const editactivitychildaction = (data) => ({
  type: types.EDIT_ACTIVITY,
  data,
});
export const activityProcess = (data) => ({
  type: types.ACTIVITY_PROCESS,
  data,
});

export const FetchEditActivity = (data) => (dispatch) => {
  shop.CallEngineEditActivities(data, (e) => {
    dispatch(editactivitychildaction(e, data));
  });
};

export const StartActivityProcess = (data) => (dispatch) => {
  shop.CallEngineActivityProcess(data, (e) => {
    dispatch(activityProcess(e, data));
  });
};
export const FetchAllActivitiesOfCamp = (data) => (dispatch) => {
  shop.CallEngineAllActivitiesOfCamp(data, (e) => {
    dispatch(allActivitiesOfCampaign(e, data));
  });
};
export const FetchActivityDetails = (data) => (dispatch) => {
  shop.CallEngineActivityDetails(data, (e) => {
    dispatch(activityDetail(e, data));
  });
};

//  ___________________ ACTIVITY CRUD END  ____________________________

//  ___________________ CALL SCRIPT CRUD END  ____________________________

export const allscriptchild = (data) => ({
  type: types.ALL_CALL_SCRIPT,
  data,
});

export const FetchAllCallScript = (data) => (dispatch) => {
  shop.CallEngineFetchCallscripts(data, (e) => {
    dispatch(allscriptchild(e, data));
  });
};

export const createcallscriptchild = (data) => ({
  type: types.CREATE_CALL_SCRIPT,
  data,
});

export const FetchAddCallScripts = (data) => (dispatch) => {
  shop.CallEngineFetchCreateCallscripts(data, (e) => {
    dispatch(createcallscriptchild(e, data));
  });
};


export const updatecallscriptchild = (data) => ({
  type: types.UPDATE_CALL_SCRIPT,
  data,
});

export const FetchEditCallScripts = (data) => (dispatch) => {
  shop.CallEngineFetchEditCallscripts(data, (e) => {
    dispatch(updatecallscriptchild(e, data));
  });
};

export const getwhatsappchannelchild = (data) => ({
  type: types.WHATSAPP_CHANNEL_GET,
  data,
});

export const FetchWhatsappChannel = (data) => (dispatch) => {
  shop.CallEngineGetWhatsappChannel(data, (e) => {
    dispatch(getwhatsappchannelchild(e, data));
  });
};

export const leadagainhistory = (data) => ({
  type: types.LEAD_HISTORY,
  data,
});

export const fetchchildchennel = (data) => ({
  type: types.FETCH_CHANNELS,
  data,
});

export const FetchPostingChannel = (data) => (dispatch) => {
  shop.CallEngineFetchCHannelPost(data, (e) => {
    dispatch(fetchchildchennel(e, data));
  });
};

export const FetchLeadAgainHistory = (data) => (dispatch) => {
  shop.CallEngineLeadPostHistory(data, (e) => {
    dispatch(leadagainhistory(e, data));
  });
};
