import React, { useState } from 'react';
import { Card, Avatar, Progress, Typography, Row, Col, Tooltip, Modal } from 'antd';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DateTimeFormatter from '../../dateformat';

const CampaignCard = ({ campaign }) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleResumeClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmation = (confirmed) => {
    setShowConfirmation(false);
    if (confirmed) {
      navigate('/activities/call-leads');
    }
  };

  return (
    <>
      <Card title={campaign?.name} bordered={false} className="min-w-[450px]">
        <Row className="gap-5 mt-3 mb-3">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Campaign Name:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{campaign?.campaign?.name}</Typography.Text>
          </Col>
        </Row>

        <Row className="gap-5 items-center justify-center">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Members</Typography.Text>
          </Col>
          <Col>
            <Avatar.Group>
              {campaign?.members?.map((v, index) => (
                <Tooltip key={index} title={v?.name}>
                  <Avatar icon={<UserOutlined />} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </Col>
        </Row>

        <Row className="gap-5 mt-3">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Call</Typography.Text>
          </Col>
          <Col>
            <Progress percent={9} showInfo={true} />
            <Typography.Text>Remaining Leads: 4/44</Typography.Text>
          </Col>
        </Row>

        <Row className="gap-5 mt-3">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Last Actioner:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>Admin</Typography.Text>
          </Col>
        </Row>

        <Row className="gap-5 mt-3">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Started On:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              <DateTimeFormatter date={campaign?.performed_on} />
            </Typography.Text>
          </Col>
        </Row>

        <Row className="gap-5 mt-4">
          <Button
            variant="outlined"
            className="w-full mt-5"
            onClick={handleResumeClick}
          >
            Resume
          </Button>
        </Row>
      </Card>

      <Modal
        title={
          <div className="flex items-center gap-2">
            <ExclamationCircleOutlined className="text-yellow-500" />
            <span style={{ marginLeft: "10px" }}>Are you sure?</span>
          </div>
        }
        open={showConfirmation}
        onOk={() => handleConfirmation(true)}
        onCancel={() => handleConfirmation(false)}
        okText="Yes"
        cancelText="No"
        width={400}
        centered
      >
        <p>Are you sure you want to proceed this Call Activity?</p>
      </Modal>
    </>
  );
};

export default CampaignCard;