import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Table, Spin } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

// ==============================|| SMS SETTINGS - MANAGEMENT ||============================== //

const AllSmsSetting = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dataSource = [
    {
      key: "1",
      smsProvider: "Twilio",
      apiKey: "APIKEY123",
      apiSecret: "APISECRET123",
      senderId: "MyCompany",
    },
    {
      key: "2",
      smsProvider: "Nexmo",
      apiKey: "APIKEY456",
      apiSecret: "APISECRET456",
      senderId: "MyBiz",
    },
  ];

  // Columns definition
  const columns = [
    {
      title: "SMS Provider",
      dataIndex: "smsProvider",
      key: "smsProvider",
    },
    {
      title: "API Key",
      dataIndex: "apiKey",
      key: "apiKey",
    },
    {
      title: "API Secret",
      dataIndex: "apiSecret",
      key: "apiSecret",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <IconButton
          className="mx-2"
          onClick={() => {
            navigate("/settings/add-sms-setting", { state: record });
          }}
        >
          <EditOutlined />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "67px",
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All SMS Settings
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Link to={"/settings/add-sms-setting"}>
            <Button
              disableElevation
              className="px-2"
              size="large"
              type="submit"
            >
              <span className="mx-2">
                <IoMdAddCircle color="#00529D" size={26} />
              </span>{" "}
              Add SMS Setting
            </Button>
          </Link>
        </Grid>
      </div>

      <MainCard>
        <Spin spinning={loading}>
          <Table
            className="border rounded"
            style={{ marginTop: 25 }}
            dataSource={dataSource ?? []}
            columns={columns}
          />
        </Spin>
      </MainCard>
    </>
  );
};

export default AllSmsSetting;
