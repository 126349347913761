import React from 'react';
import { format } from 'date-fns';

const DateTimeFormatter = ({ date, showTime = true }) => {
  if (!date) return null;

  try {
    const dateObj = new Date(date);
    const dateFormat = showTime 
      ? "MMM dd, yyyy 'at' hh:mm a"  // Oct 29, 2024 at 10:10 AM
      : "MMM dd, yyyy";              // Oct 29, 2024
    
    return (
      <span className="text-gray-600">
        {format(dateObj, dateFormat)}
      </span>
    );
  } catch (error) {
    return <span className="text-red-500">Invalid date</span>;
  }
};

export default DateTimeFormatter;