import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Pagination, Button, Modal, Form, Input } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { dispatch } from "store/index";
import { allCallScriptColumns } from "components/columns/columns";
import { FetchAllCallScript, FetchAddCallScripts,FetchEditCallScripts } from "store/action/index";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";

const AllCallScript = ({ allCallscript }) => {
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState({ page: 1 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  useEffect(() => {
    setLoading(true);
    dispatch(FetchAllCallScript({ setLoading: setLoading, params: params }));
  }, [params]);


  const handlePageChange = (page) => {
    setParams({ ...params, page });
  };

  const showModal = () => {
    setIsEditMode(false); 
    setIsModalVisible(true);
  };


  const onEdit = (record) => {
    console.log("Editing Record:", record);
    setIsEditMode(true);
    setCurrentRecord(record);
    setIsModalVisible(true);
    setEditorValue(record.description);
    form.setFieldsValue(record);
  };

  // const handleSubmit = (values) => {
  //   let fieldData = values;
  //   dispatch(FetchAddCallScripts({ fieldData }));
  //   setIsModalVisible(false);
  //   form.resetFields();
  // };

  const handleSubmit = (values) => {
    const fieldData = {
        ...values,
        description: editorValue,
        ...(isEditMode && { _method: "PUT" })  
    };
    if (isEditMode && currentRecord?.id) {
        console.log("recorddddddddddd", currentRecord.id);
        dispatch(FetchEditCallScripts({ ...fieldData, id: currentRecord.id }));
    } else {
        dispatch(FetchAddCallScripts({ fieldData }));
    }
    setIsModalVisible(false);
    form.resetFields();
    setEditorValue("");
};


  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };


  const columns = allCallScriptColumns({ params, setParams });

  columns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <IconButton
        className="mx-2"
        type="primary"
        variant="contained"
        onClick={() => onEdit(record)}
      >
        <EditOutlined />
      </IconButton>
    ),
  });

  return (
    <>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Call Scripts
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Button
                  disableElevation
                  className="px-2"
                  size="large"
                  onClick={showModal}
                >
                  <span className="mx-2">
                    <IoMdAddCircle color="#0055A2" size={26} />
                  </span>
                  Add Call Script
                </Button>
              </Grid>
            </Grid>
          </div>
        </MainCard>
      </div>

      <MainCard>
        <Table
          dataSource={allCallscript?.data ?? []}
          columns={columns}
          pagination={false}
          loading={loading}
        />

        <div style={{ textAlign: "center", marginTop: 16 }}>
          <Pagination
            current={allCallscript?.meta?.current_page || 1}
            total={allCallscript?.meta?.total || 0}
            pageSize={allCallscript?.meta?.per_page || 10}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </MainCard>

      <Modal
        title={isEditMode ? "Edit Call Script" : "Add Call Script"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}

      >
        <Form form={form} onFinish={handleSubmit} className='mt-4' layout='vertical'>
          <Form.Item
            name="script"
            label="Script Name"
            rules={[
              { required: true, message: "Please input the script name!" },
            ]}
          >
            <Input placeholder="Enter script name" />
          </Form.Item>
          <Form.Item
            label="Call Script"
            name="description"
            rules={[
              {
                required: true,
                validator: () => {
                  return editorValue == '<p> </p>' || editorValue === '<p><br></p>' || editorValue == ''
                    ? Promise.reject("Call script is required") : Promise.resolve();
                },
              },
            ]}
          >
            <TextRichEditor value={editorValue} onChange={setEditorValue} placeholder={"Call Script"} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allCallscript: state?.AllCallScriptReducer?.allCallscript,
  };
};

export default connect(mapStateToProps)(AllCallScript);
