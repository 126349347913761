import MainCard from "components/MainCard";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchAllWhatsappTemplates ,FetchPostingChannel} from "store/action/index";
import { dispatch } from "store/index";
import { Table, Modal, Spin, Tag } from "antd";
import IconButton from "@mui/material/IconButton";
import { EditOutlined } from "@ant-design/icons";
import { IoMdAddCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const WhatsappTemplate = ({ whatsappTemplates }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(FetchAllWhatsappTemplates({ setLoading: setLoading }));
  }, []);

  const renderButtons = (text) => {
    try {
      const parsedBody = JSON.parse(text);
      if (!parsedBody?.content?.keyboard?.rows) return null;

      return (
        <div className="flex flex-col gap-2">
          {parsedBody.content.keyboard.rows.map((row, rowIndex) => (
            <div key={rowIndex} className="flex gap-2">
              {row.buttons.map((button, buttonIndex) => {
                let buttonContent;
                if (button.buttonType === "URL") {
                  buttonContent = (
                    <Tag color="blue">
                      URL: {button.text} ({button.url})
                    </Tag>
                  );
                } else if (button.buttonType === "NUMBER") {
                  buttonContent = (
                    <Tag color="green">
                      Number: {button.text}
                    </Tag>
                  );
                }
                return (
                  <div key={buttonIndex}>
                    {buttonContent}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      );
    } catch (error) {
      console.error("Error parsing buttons:", error);
      return null;
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "content",
      render: (text) => {
        try {
          const parsedBody = JSON.parse(text);
          return parsedBody?.content?.text || text;
        } catch (error) {
          return text;
        }
      }
    },
    {
      title: "Footer",
      dataIndex: "footer",
      key: "footer",
      render: (text) => {
        try {
          const parsedFooter = JSON.parse(text);
          return parsedFooter?.text || text;
        } catch (error) {
          return text;
        }
      }
    },
    {
      title: "Buttons",
      dataIndex: "body",
      key: "buttons",
      render: renderButtons
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => {
    //     const editButton = true ? (
    //       <IconButton
    //         className="mx-2"
    //         type="primary"
    //         variant="contained"
    //         onClick={() => navigate(`/templates/edit-whatsapp-template/${record.id}`)}
    //       >
    //         <EditOutlined />
    //       </IconButton>
    //     ) : null;

    //     return <span>{editButton}</span>;
    //   },
    // }
  ];

  const HandleNavigate = () => {
    navigate("/templates/create-whatsapp-templates");
  };

  const HandleSyncFetch=()=>{
    setLoading(true)
    dispatch(FetchPostingChannel({setLoading:setLoading}));
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "67px",
          borderRadius: "10px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Whatsapp Templates
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          {true ? (
            <>
                <Button
                disableElevation
                className="px-2"
                size="large"
                onClick={HandleSyncFetch}
                type="submit"
              >
                Synx
              </Button>
            <Button
              disableElevation
              className="px-2"
              size="large"
              onClick={HandleNavigate}
              type="submit"
            >
              <span className="mx-2">
                <IoMdAddCircle color="#FF264C" size={26} />
              </span>{" "}
              Add Template
            </Button>
            </>

          ) : null}
        </Grid>
      </div>

      <MainCard>
        <Spin spinning={loading}>
          <Table
            className="border rounded"
            style={{ marginTop: 25 }}
            dataSource={whatsappTemplates ?? []}
            columns={columns}
          />
        </Spin>
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    whatsappTemplates: state.WhatsappTemplatesReducer.whatsappTemplates,
  };
};

export default connect(mapStateToProps)(WhatsappTemplate);