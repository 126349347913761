import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { dispatch } from "store/index";
import { smsLogColumns } from "components/columns/columns";
import { FetchAllSmSLogs } from "store/action/index";
import { textAlign } from "../../../node_modules/@mui/system/index";

const SmsLogs = ({ allsmslogs }) => {

  const [loading, setLoading] = useState(false);
  const [params, setparams] = useState({ page: 1 });



  useEffect(() => {
    setLoading(true);
    dispatch(FetchAllSmSLogs({ setLoading: setLoading }));
  }, []);


  useEffect(() => {
    console.log("allcompaigns", allsmslogs)
  }, [allsmslogs]);

  const columns = smsLogColumns({ params, setparams });

  //   const handlePageChange = (page) => {
  //     setparams({ ...params, page });
  //   };

  const allCampaignsData = [
    {
      key: '1',
      LeadName: 'John Doe',
      Phone: '+1234567890',
      SentTime: '2024-10-14 10:00:00',
      status: 'Sent'
    },
    {
      key: '2',
      LeadName: 'Jane Smith',
      Phone: '+0987654321',
      SentTime: '2024-10-14 09:30:00',
      status: 'Failed'
    },
    {
      key: '3',
      LeadName: 'Michael Johnson',
      Phone: '+1122334455',
      SentTime: '2024-10-14 11:45:00',
      status: 'Read'
    },
    {
      key: '4',
      LeadName: 'Emily Davis',
      Phone: '+2233445566',
      SentTime: '2024-10-14 08:15:00',
      status: 'Sent'
    }
  ];


  return (
    <>
      <MainCard>
        <Table
          dataSource={allCampaignsData}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey="id"
        />
        {/* <div style={{ textAlign: "center", marginTop: 16 }}>
                <Pagination
                    current={allcompaigns?.meta?.current_page || 1}
                    total={allcompaigns?.meta?.total || 0}
                    pageSize={allcompaigns?.meta?.per_page || 10}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </div> */}
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allsmslogs: state?.AllSmsLogsReducer?.allsmslogs,

  };
};

export default connect(mapStateToProps)(SmsLogs);
