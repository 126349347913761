// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { HiUsers } from "react-icons/hi2";
import { IoSettings } from "react-icons/io5";
import {
    can_add_users,
    can_edit_users,
    can_view_users,
} from "helper/permissionsCheck";
import DrawerRight from "components/drawer/AddCampaignDrawer";
import { Typography } from '@mui/material'
// icons
const icons = {
    navigation: MdHome,
    flatType: TbHomeCog,
    amenities: BsBuildingFillAdd,
    flatSize: TbRulerMeasure,
    taxes: HiOutlineReceiptTax,
    flat: MdOutlineBedroomParent,
    assignPermission: MdOutlineSecurity,
    color: MdHome,
    shadow: MdHome,
    AllUsers: IoSettings,
};

const setting =
    can_add_users || can_edit_users || can_view_users
        ? {
            id: "ddall-Settings",
            title: "Settings",
            icon: icons.AllUsers,
            type: "group",
            children: [
                can_add_users || can_edit_users || can_view_users
                    ? {
                        id: "email-Setting",
                        title: "Email Setting",
                        type: "item",
                        url: "/settings/all-email-setting",
                        icon: icons.AllUsers,
                        breadcrumbs: true,
                    }
                    : null,

                can_add_users
                    ? {
                        id: "whatsApp-setting",
                        title: "WhatsApp Setting",
                        type: "item",
                        url: "/settings/all-whatsapp-setting",
                        icon: icons.AllUsers,
                        breadcrumbs: true,
                    }
                    : null,
                can_add_users
                    ? {
                        id: "sms-settings",
                        title: "SMS Setting",
                        type: "item",
                        url: "/settings/all-sms-setting",
                        icon: icons.AllUsers,
                        breadcrumbs: true,
                    }
                    : null,
            ].filter(Boolean),
        }
        : null;

export default setting;
