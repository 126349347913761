import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Spin, Input, Select, message } from "antd";
import MainCard from "components/MainCard";
import { dispatch } from "store/index";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { CreateTeam, GetAllUsers, UpdateTeam } from "store/action/index";
import { getDecryptedUserData } from "helper/helper";
const { TextArea } = Input;

const Addteam = ({ allTeamMembers }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [members, setMembers] = useState([]);

  useEffect(() => {
    dispatch(GetAllUsers({ setLoading: setIsLoading }));
    if (location?.state?.id) {
      setIsEditMode(true);
    }
  }, [location?.state]);

  useEffect(() => {
    if (!isLoading && isEditMode && location?.state) {
      form.setFieldsValue({
        name: location?.state?.name || "",
        teamMembers: location?.state?.users?.map((member) => member.id) || [],
      });
    }
  }, [isLoading, isEditMode, location?.state, form]);

  const navigateToAllTeams = () => {
    navigate("/teams/all-teams");
  };

  const onFinish = (values) => {
    let userData = getDecryptedUserData();
    if (userData) {
      userData = JSON.parse(userData);
    }

    if (isEditMode) {
      // Dispatch update team action here
      dispatch(
        UpdateTeam({
          fieldData: {
            ...values,
            members,
            _method: "PUT",
            id: location?.state?.id,
          },
          setLoading: setIsLoading,
          isNavigate: navigateToAllTeams,
        })
      );
    } else {
      dispatch(
        CreateTeam({
          setLoading: setIsLoading,
          fieldData: { ...values, members },
          isNavigate: navigateToAllTeams,
        })
      );
    }
  };

  const handleTeamMembersChange = (value, options) => {
    const formattedMembers = options.map(({ item }) => ({
      user_id: item.item.id,
      role: item.item.roles[0]?.id,
    }));
    setMembers(formattedMembers);
  };

  const teamOptions = Array.isArray(allTeamMembers?.users)
    ? allTeamMembers?.users?.map((member) => ({
        label: member.name,
        value: member.id,
        item: member,
      }))
    : [];

  return (
    <MainCard>
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter the team name" },
                ]}
              >
                <Input placeholder="Enter team name" className="p-2" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Team Members"
                name="teamMembers"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one team member",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select team members"
                  onChange={handleTeamMembersChange}
                >
                  {teamOptions.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}
                      item={option}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update Team" : "Create Team"}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainCard>
  );
};

const mapStateToProps = (state) => ({
  allTeamMembers: state?.GetAllUsers?.getAllUsers,
});

export default connect(mapStateToProps)(Addteam);
